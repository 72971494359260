import React, { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import folder from "../../Assets/Images/icon/folder.svg";
import uploadFile from "../../Assets/Images/icon/uploadIcon.svg";
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';
import fileFormat from "../../Assets/Images/icon/file-05.svg";
import { convertBytesToSize } from '../../CommonFunction/Function';
import trash from "../../Assets/Images/icon/trash-02.svg";
import memberIcon from "../../Assets/Images/icon/memberAvatar.png";
import { DESKIE_API as API } from '../../config';
import { v4 as uuidv4 } from 'uuid';
import { exteriorCreate, mailStatus } from '../../api/mailBox';
import { allMemberList } from '../../api/member';

interface MailFileProps {
    handleUploadClose: () => void;
    uploadShow: boolean;
    setUploadShow: (type: boolean) => void;
}
const MailUpload = ({ uploadShow, setUploadShow, handleUploadClose }: MailFileProps) => {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const [nickName, setNickName] = useState("");

    const [file, setFile] = useState("");
    const [userImage, setUserImage] = useState("");
    const wrapperRef = useRef<HTMLInputElement>(null);
    const company = localStorage.getItem("company");
    const userInfo = company ? JSON.parse(company) : null;
    const [isActive, setIsActive] = useState(false);
    const [selectName, setSelectName] = useState("");
    const [memberImage, setMemberImage] = useState("");
    const [selectEmail, setSelectEmail] = useState("");
    const [member, setMember] = useState([]);
    const [searchMembers, setSearchMembers] = useState("");

    useEffect(() => {
        allMemberList(20, 1, searchMembers)
            .then((data) => {
                setMember(data.members);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [searchMembers]);


    const onFileDrop = (event: any) => {
        const imageFile = event.target.files && event.target.files[0];
        setNickName(imageFile.name);
        if (imageFile && imageFile.size <= 26 * 1024 * 1024) { // Convert MB to bytes
            setFile(imageFile);
            if (imageFile && uploadedFiles.length === 0) {
                setUploadedFiles([imageFile]);
            }
        } else {
            showNotifications(TOAST_TYPE.error, 'Maximum upload size 26 MB');
        }
    }
    const removeFile = () => {
        setUploadedFiles([]);
    }



    const mailSend = () => {
        let files: any = {
            "sender": userInfo.user.email,
            "recipient": selectEmail,
            "exterior": file,
            "exterior_name": nickName,
            "exterior_size": uploadedFiles.reduce((totalSize, file) => totalSize + file.size, 0),
            "status": "pending"

        }
        exteriorCreate(files).then(() => {
            showNotifications(TOAST_TYPE.success, 'File Uploaded', `${files.name}`);
            setUploadedFiles([]);
            setNickName("");
        }).catch(() => {
            showNotifications(TOAST_TYPE.error, 'Something wrong');
        }).finally(() => {
            setUploadShow(false);
        })
    }

    const selectMember = (member: any) => {
        setSelectName(`${member.first_name} ${member.last_name}`);
        setSelectEmail(member.email);
        // setMemberId(member.id);
        setMemberImage(member.member_image);
        // setMemberError(false);
    };


    const handleMemberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchMembers(e.target.value);
    };

    const filteredMembers = member?.filter(
        (member: any) =>
            member.first_name.toLowerCase().includes(searchMembers.toLowerCase()) ||
            member.last_name.toLowerCase().includes(searchMembers.toLowerCase()) ||
            member.email.toLowerCase().includes(searchMembers.toLowerCase())
    );

    

    return (
        <div>
            <Modal show={uploadShow} onHide={handleUploadClose} centered size="lg">
                <div className="addMemberForm">
                    <button className='closeModal' onClick={handleUploadClose}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>

                    <div className='addMemberHeading'>
                        <img src={folder} alt="member" />
                        <p>Upload File</p>
                    </div>
                    <div className="fileSendInfo ">
                        <div className="fileNameType">
                            <label htmlFor="name" className='mb-3'>Recipient</label>
                            <div className='memberInfos assignBox w-100'>
                                <div className='dropdown w-100'>
                                    <div
                                        onClick={(e) => {
                                            setIsActive(!isActive);
                                        }}
                                        className='dropdown-btn'
                                    >
                                        <div className='d-flex tableImage'>
                                            {selectEmail.length ? (
                                                <>
                                                    {memberImage ? (
                                                        <img src={`${API}/${memberImage}`} alt='avatar' style={{ objectFit: "cover" }} />
                                                    ) : (
                                                        <img className='default' src={memberIcon} alt='avatar' />
                                                    )}
                                                    <div>
                                                        <p>
                                                            {selectName.length > 40
                                                                ? selectName.substring(0, 40) + "..."
                                                                : selectName}
                                                        </p>
                                                        <span>
                                                            {selectEmail.length > 40
                                                                ? selectEmail.substring(0, 40) + "..."
                                                                : selectEmail}
                                                        </span>
                                                    </div>
                                                </>
                                            ) : (<div><p>Who is this mail addressed to?</p></div>)}
                                        </div>
                                        <span>
                                            <FontAwesomeIcon icon={faChevronDown} />
                                        </span>
                                    </div>
                                    <div className='dropdown-content' style={{ display: isActive ? "block" : "none" }}>
                                        <div className='assignInput px-2'>
                                            <FontAwesomeIcon icon={faSearch} />
                                            <input type='text' placeholder='Search member' onChange={handleMemberChange} className='form-control' />
                                        </div>
                                        <div className='member-container'>
                                            {filteredMembers.map((data: any, index) => (
                                                <div key={`assignMember` + index} onClick={(e) => { setIsActive(!isActive); selectMember(data); }} className='item tableImage my-2'>
                                                    {data.member_image ? (
                                                        <img src={`${API}/${data.member_image}`} alt='avatar' style={{ objectFit: "cover" }} />
                                                    ) : (
                                                        <img className='default' src={memberIcon} alt='avatar' />
                                                    )}
                                                    <p>{`${(data.first_name + " " + data.last_name).slice(0, 17)}${(data.first_name + " " + data.last_name).length > 17 ? "..." : ""}`}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="userInfo mt-4 mb-4">
                        {userImage && userImage ? <img src={`${API}/${userImage}`} className="adminBorder" alt="admin" /> : <img src={memberIcon} alt="" className="adminBorder" />}
                        <p>{userInfo.user.name} (you)</p>
                    </div>
                    <div className="mailOptions mb-4">
                        <p>Exterior (Outside)</p>
                    </div>
                    <div ref={wrapperRef} className="drop-file-input mb-4">
                        <div className="drop-file-input__label">
                            <img src={uploadFile} alt="" />
                            <p><span>Click to upload</span> or drag and drop</p>
                            <h6>Maximum upload size <b>26 MB</b></h6>
                        </div>
                        <input type="file" value="" onChange={onFileDrop} />
                    </div>
                    {uploadedFiles && uploadedFiles.map((file, index) =>
                        <div className="uploadFileShow">
                            <div className="fileFormat">
                                <img src={fileFormat} alt="file" />
                            </div>
                            <div className="fileName">
                                <p>{file.name}</p>
                                <span>{convertBytesToSize(file.size)} – 100% uploaded</span>
                            </div>
                            <div className="fileDelete" onClick={removeFile}>
                                <img src={trash} alt="trash" />
                            </div>
                        </div>
                    )}
                    {/* Interior (Contents) */}
                    {/* <div ref={wrapperRef} className="drop-file-input mb-4">
                            <div className="drop-file-input__label">
                                <img src={uploadFile} alt="" />
                                <p><span>Click to upload</span> or drag and drop</p>
                                <h6>Maximum upload size <b>26 MB</b></h6>
                            </div>
                            <input type="file" value="" onChange={onInteriorFileDrop} />
                        </div>
                        {interiorFiles && interiorFiles.map((file, index) =>
                            <div className="uploadFileShow">
                                <div className="fileFormat">
                                    <img src={fileFormat} alt="file" />
                                </div>
                                <div className="fileName">
                                    <p>{file.name}</p>
                                    <span>{convertBytesToSize(file.size)} – 100% uploaded</span>
                                </div>
                                <div className="fileDelete" onClick={removeInteriorFile}>
                                    <img src={trash} alt="trash" />
                                </div>
                            </div>
                        )} */}
                    <div className="uploadBtn">
                        {uploadedFiles && uploadedFiles.length === 0 ? <button className='btn noFile' type='submit'>Save</button>
                            : <button className='btn save' type='submit' onClick={mailSend}>Save</button>}
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default MailUpload