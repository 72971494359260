import { get, post, put, del } from "./base-api";


export const addAssignment = async (spaceID: string, body = {}) => {
  return post(`/space/${spaceID}/assign`, body);
};
export const getAssignment = async (limit: number, page: number) => {
  return get(`/getAssignment?limit=${limit}&page=${page}`);
};
export const assignmentUpdate = async (body = {}) => {
  return put(`/assignmentUpdate`, body);
};

export const deleteAssignment = async (id: string) => {
  return del(`/deleteAssignment/${id}`);
};
