import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import "./Pagination.css";
import { paginationNumber } from '../../CommonFunction/Function';

interface PaginationProps {
    prevPage: () => void;
    nextPage: () => void;
    limit: number;
    page: number;
    setLimit: (value: number) => void;
    prevButton: boolean;
    nextButton: boolean;
    pageValue: any;
    totalValue: any;
    allRequestList?: any;
    setPage: any;
    paginationTitle: string;
    margin?: string;
    padding?: string;
    type?: string;
}

const Pagination = ({ page, setPage, limit, prevPage, nextPage, prevButton, nextButton, totalValue, margin="mt-2",padding,type}: PaginationProps) => {
    const paginationDivided = paginationNumber(totalValue, limit);

    const generatePaginationString = () => {
        if(totalValue === 0) {
            return ''
        };
        const start = (page - 1) * limit + 1;
        const end = Math.min(page * limit, totalValue); 
        return `${start} - ${end} of ${totalValue}`;
    };

    return (
        <>
            <div className={`paginationBox ${margin} ${padding} ${type ==='upcoming' ? 'mt-lg-4 px-0':'px-4'}`}>
                <div className="tableNumber d-lg-flex">
                    <div className="paginationNumber justify-content-end w-50 d-lg-none">
                    <button onClick={() => prevPage()} className={prevButton === true ? "" : "disablePag"}><FontAwesomeIcon icon={faArrowLeft} className='mt-1 mt-lg-0' /> 
                    <span className='paginationButtonText'>
                        Previous
                    </span>
                    </button>
                    {paginationDivided.map((number) => 
                        <button key={`page` + number} onClick={() => setPage(number)} style={{ borderLeft: 'none', borderRight: 'none',fontSize:'14px' }}>
                            <span className='mt-1 mt-lg-0'>{number}</span>
                        </button>
                    )}
                    <button onClick={() => nextPage()} className={nextButton === true ? "" : "disablePag"}>
                        <span className='paginationButtonText'>
                            Next
                        </span>
                        <FontAwesomeIcon icon={faArrowRight} className='mt-1 mt-lg-0'/>
                    </button>
                </div>
                    <p>{generatePaginationString()}</p>
                </div>
                <div className="paginationNumber d-none d-lg-flex">
                    <button onClick={() => prevPage()} className={prevButton === true ? "" : "disablePag"}><FontAwesomeIcon icon={faArrowLeft} className='mt-1 mt-lg-0' /> 
                    <span className='paginationButtonText'>
                        Previous
                    </span>
                    </button>
                    {paginationDivided.map((number) => 
                        <button key={`page` + number} onClick={() => setPage(number)} style={{ borderLeft: 'none', borderRight: 'none',fontSize:'14px' }}>
                            <span className='mt-1 mt-lg-0'>{number}</span>
                        </button>
                    )}
                    <button onClick={() => nextPage()} className={nextButton === true ? "" : "disablePag"}>
                        <span className='paginationButtonText'>
                            Next
                        </span>
                        <FontAwesomeIcon icon={faArrowRight} className='mt-1 mt-lg-0'/>
                    </button>
                </div>
            </div>
        </>
    )
}

export default Pagination