import { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { addPaymentMethod, stripeSetupIntent } from "../../../api/settings";
import { showNotifications, TOAST_TYPE } from "../../../CommonFunction/toaster";


const MyAccount = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null | undefined>(null);

  const company = localStorage.getItem("company");
  const {user} = company ? JSON.parse(company) : null;
  

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      setLoading(true);
      setError(null);

      const stripeIntentData = await stripeSetupIntent();

      if (!stripeIntentData.client_secret) {
        showNotifications(TOAST_TYPE.error, "Stripe client secret is not defined");
        throw new Error("STRIPE_CLIENT_SECRET is not defined");
      }

      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        console.error("CardElement not found");
        setLoading(false);
        return;
      }

      const { setupIntent, error: setupError } = await stripe.confirmCardSetup(
        stripeIntentData.client_secret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );

      if (setupError) {
        setError(setupError.message);
      } else {
        const payload = {
          payment_method: setupIntent.payment_method,
          customer_id: stripeIntentData.customer_id
        }
        await addPaymentMethod(payload);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className='companyProfile'>
        <div className='profileHeading'>
          <h6>Billing Settings</h6>
        </div>
        <div className='profileSave'>
          <button
            className='save'
            onClick={handleSubmit}
            disabled={!stripe || loading}
          >
            {loading ? "Processing..." : "Save"}
          </button>
        </div>
      </div>

      <CardElement />
      {error && <div style={{ color: "red" }}>{error}</div>}
    </>
  );
};

export default MyAccount;
