import React, { useState, useEffect } from "react";
import creditCard from "../../Assets/Images/icon/credit_card.svg";
import { paymentHook, paymentProcess } from "../../api/payment";
import { DESKIE_API as API } from "../../config";
import { Modal } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { invoiceUpdate } from "../../api/invoice";
import { showNotifications, TOAST_TYPE } from "../../CommonFunction/toaster";
import { isAuthenticate } from "../../api/auth";
import { addPaymentMethod, getStripe, stripeSetupIntent } from "../../api/settings";


interface AddPaymentProps {
  handlePaymentClose: () => void;
  paymentShow: boolean;
  setPaymentShow: (type: boolean) => void;
  invoiceId: any;
  invoiceDetail: any;
}

const options = {
  style: {
    base: {
      fontSize: "15px",
      fontWeight: 400,
      color: "rgba(54, 54, 55, 1)",
      letterSpacing: "0.025em",
      fontFamily: "inter",
      "::placeholder": {
        color: "rgba(116, 118, 121, 0.8)",
      },
    },
    invalid: {
      color: "rgba(54, 54, 55, 1)",
    },
  },
};

const CardPaymentOption = ({
  invoiceId,
  setPaymentShow,
  invoiceDetail,
}: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardName, setCardName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [amountValue, setAmountValue] = useState(invoiceDetail.amount);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  let auth = isAuthenticate();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (paymentLoading) return;
    setPaymentLoading(true);

    if (!stripe || !elements) {
      console.error("Stripe.js has not loaded yet.");
      return;
    }

    paymentProcess({ amount: amountValue * 100 })
      .then(async (data) => {
        if (elements) {
          const cardElement = elements.getElement(CardNumberElement);
          if (cardElement) {
            const { paymentIntent, error } = await stripe.confirmCardPayment(
              data.clientSecret,
              {
                payment_method: {
                  card: cardElement,
                },
              }
            );
            if (error) {
              console.log(error);
              showNotifications(TOAST_TYPE.error, "Wrong information");
            } else if (paymentIntent && paymentIntent.status === "succeeded") {
              // const hookData = await paymentHook({ clientSecret: data.clientSecret });
              console.log("Payment succeeded:");
              let paymentInfo: any = {
                id: uuidv4(),
                invoiceId: invoiceId,
                invoiceNumber: invoiceDetail.invoice_id,
                userId: auth.user.id,
                amount: amountValue,
                invoiceAmount: amountValue,
                paymentDate: new Date(),
                method: "card",
                paymentNote: "",
                status: "",
                card_name: cardName,
                city: city,
                street: street,
                state: state,
                zip_code: zip,
                stripe_id: data.clientSecret,
              };
              invoiceUpdate(paymentInfo)
                .then((data) => {
                  showNotifications(TOAST_TYPE.success, data.message);
                })
                .catch((err) => {
                  // showNotifications(TOAST_TYPE.error, err.message);
                  console.log(err);
                })
                .finally(() => {
                  setPaymentShow(false);
                });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPaymentLoading(false);
    });

    if(saveCard) {
      try {
        event.preventDefault();
  
        const stripeIntentData = await stripeSetupIntent();
  
        if (!stripeIntentData.client_secret) {
          showNotifications(TOAST_TYPE.error, "Stripe client secret is not defined");
          throw new Error("STRIPE_CLIENT_SECRET is not defined");
        };
  
        const cardElement = elements.getElement(CardNumberElement);
        if (!cardElement) {
          console.error("CardElement not found");
          return;
        }
  
        const { setupIntent, error: setupError } = await stripe.confirmCardSetup(
          stripeIntentData.client_secret,
          {
            payment_method: {
              card: cardElement,
            },
          }
        );
  
        if (setupError) {
          console.log(setupError);
        } else {
          const payload = {
            payment_method: setupIntent.payment_method,
            customer_id: stripeIntentData.customer_id
          }
          await addPaymentMethod(payload);
        }
      } catch (err) {
        console.log(err);
      }
    }

  };

  return (
    <>
      <div className='resourcePayHeading'>
        <h6 style={{ marginBottom: "32px" }}>
          <img src={creditCard} className='mr-2' alt='credit' /> Payment
        </h6>
        <div className='mobileBillHeading'>
          <p>Payment</p>
          <p className='amount'>{amountValue}</p>
        </div>
      </div>
      <div className='resourcePayment'>
        <form onSubmit={handleSubmit} className='DemoWrapper'>
          <div className='cardPayment'>
            <h6 className='mb-2'>Payment Details</h6>
            <div className='cardNameAmount'>
              <div className='resourceInput'>
                <label>Name on Card</label>
                <input
                  className='form-control'
                  value={cardName}
                  onChange={(e) => setCardName(e.target.value)}
                  placeholder='Name on card'
                />
              </div>
              <div className='resourceInput billingAmount'>
                <label>Amount</label>
                <input
                  className='form-control'
                  disabled
                  type='number'
                  value={amountValue}
                  onChange={(e) => setAmountValue(parseInt(e.target.value))}
                  placeholder='Name on card'
                />
              </div>
            </div>
            <div className='cardNumber resourceCard mt-0'>
              <div className='cardInput'>
                <label>Card Number</label>
                <CardNumberElement options={options} />
              </div>
              <div className='cardInput expire'>
                <label>Expiration Date</label>
                <CardExpiryElement options={options} />
              </div>
              <div className='cardInput cvv'>
                <label>CVV</label>
                <CardCvcElement options={options} />
              </div>
            </div>
          </div>
          <h6 className='mb-2 mt-4'>Billing Details</h6>
          <div className='d-flex justify-content-between mobileBilling'>
            <div className='memberInput'>
              <label>Street Address</label>
              <input
                type='text'
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                placeholder='Street Address'
                className='form-control'
              />
            </div>
            <div className='memberInput'>
              <label>City</label>
              <input
                type='text'
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder='City'
                className='form-control'
              />
            </div>
          </div>
          <div className='d-flex justify-content-between mobileBilling'>
            <div className='memberInput'>
              <label>State / Province</label>
              <input
                type='text'
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder='State / Province'
                className='form-control'
              />
            </div>
            <div className='memberInput'>
              <label>Zip Code</label>
              <input
                type='text'
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                placeholder='Zip Code'
                className='form-control'
              />
            </div>
          </div>

          <div className="customCheckbox-wrapper">
            <div className="customCheckbox">
              <input type="checkbox" name="saveCard" id="saveCard" 
                onChange={() => setSaveCard(!saveCard)}
                checked={saveCard}
              />
              <span className="checkmark"></span>
            </div>
            <label className="" htmlFor="saveCard">
              Save Card for future Payments
            </label>
          </div>
          {paymentLoading ? (
            <div className='loading-button-right'>
              <button></button>
            </div>
          ) : (
            <div className='memberAddBtn'>
              <button type='submit' className='save'>
                Submit Payment ({amountValue})
              </button>
            </div>
          )}
        </form>
      </div>{" "}
    </>
  );
};

const BillPayment = ({
  invoiceId,
  paymentShow,
  setPaymentShow,
  handlePaymentClose,
  invoiceDetail,
}: AddPaymentProps) => {
  const [stripePromise, setStripePromise] = useState<any>("");
  useEffect(() => {
    getStripe()
      .then((data) => {
        setStripePromise(data.data.publish_key);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Modal
      show={paymentShow}
      onHide={handlePaymentClose}
      centered
      size='lg'
      id='newBooking'
      className='mobileBilling'
    >
      <div className='addMemberForm'>
        <button className='closeModal' onClick={handlePaymentClose}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
        <div className='resourcePay'>
          {stripePromise ? (
            <Elements stripe={loadStripe(stripePromise)}>
              <CardPaymentOption
                invoiceId={invoiceId}
                setPaymentShow={setPaymentShow}
                invoiceDetail={invoiceDetail}
              />
            </Elements>
          ) : (
            ""
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BillPayment;
