import React, { useState, useEffect, forwardRef } from "react";
import { Col, Container, Dropdown, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faXmark,
  faChevronDown,
  faSearch,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import assignmentIcon from "../../Assets/Images/icon/assetsIcon.svg";
import { v4 as uuidv4 } from "uuid";
import { getSpacesList } from "../../api/spaces";
import { DESKIE_API as API } from "../../config";
import calenderIcon from "../../Assets/Images/icon/calendar.svg";
import { showNotifications, TOAST_TYPE } from "../../CommonFunction/toaster";
import { singleMember } from "./../../api/member";
import DatePicker from "react-datepicker";
import { maskEmail } from "../../CommonFunction/Function";
import memberIcon from "../../Assets/Images/icon/memberAvatar.svg";
import spaceIcon from "../../Assets/Images/icon/spaceAvatar.png";
import { addAssignment } from "../../api/assignments";

interface AssignSpacesProps {
  handleAssignClose: () => void;
  assignShow: boolean;
  setAssignShow: (type: boolean) => void;
  memberId: string;
}

const AssignSpaces = ({
  memberId,
  assignShow,
  setAssignShow,
  handleAssignClose,
}: AssignSpacesProps) => {
  const [authValue, setAuthValue] = useState(false);
  const [name, setName] = useState("");
  const [rate, setRate] = useState("");
  const [email, setEmail] = useState("");
  const [selectName, setSelectName] = useState("");
  const [selectEmail, setSelectEmail] = useState("");
  const [memberImage, setMemberImage] = useState("");
  const [spaceImage, setSpaceImage] = useState("");
  const [spacesList, setSpacesList] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [searchMembers, setSearchMembers] = useState("");
  const [spacesId, setSpacesId] = useState("");
  const [amount, setAmount] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [spaceError, setSpaceError] = useState(false);

  const initialRenewalDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    date.setDate(1);
    return date;
  };
  const [renewalDate, setRenewalDate] = useState<any>(initialRenewalDate());
  const [frequency, setFrequency] = useState("monthly");

  useEffect(() => {
    getSpacesList(20, 1, searchMembers)
      .then((data) => {
        setSpacesList(data.spaces);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [searchMembers]);

  const filteredSpaces = spacesList?.filter((member: any) =>
    member.name.toLowerCase().includes(searchMembers.toLowerCase())
  );

  useEffect(() => {
    if (!memberId) return;

    singleMember(memberId)
      .then((data) => {
        setName(data.data && data.data.first_name);
        setEmail(data.data && data.data.email);
        setSpaceImage(data.data && data.data.member_image);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [memberId]);

  const authClick = () => {
    setAuthValue(!authValue);
    if (authValue === false) {
      const today = new Date();
      const lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      );
      const daysRemaining = lastDayOfMonth.getDate() - today.getDate() + 1;
      const daysInMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      ).getDate();
      const rentForCurrentMonth = (
        (parseInt(amount) / daysInMonth) *
        daysRemaining
      ).toFixed(2);
      setDiscountAmount(rentForCurrentMonth.toString());
    } else {
      setDiscountAmount("");
    }
  };

  const selectMember = (spaces: any) => {
    setSelectName(spaces.name);
    setSpacesId(spaces.id);
    setRate(spaces.rate);
    setMemberImage(spaces.space_image);
    setAmount(spaces.rate);
    setSpaceError(false);
  };

  const handleMemberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchMembers(e.target.value);
  };

  const bookSpace = () => {
    if(!spacesId) {
      setSpaceError(true);
      return;
    }

    const company = localStorage.getItem("company");
    const userInfo = company ? JSON.parse(company) : null;
    let invId = uuidv4();
    let invoiceInfo = {
      id: invId,
      spaces_id: spacesId,
      member_id: memberId,
      amount: discountAmount ? discountAmount : amount,
      renewal_date: renewalDate,
      renewal_frequency: authValue ? "today" : frequency,
      user_email: userInfo.user.email,
      invoice_type: "invoice",
    };
    let assignInfo = {
      id: uuidv4(),
      invoice_id: invId,
      member_id: memberId,
      amount: discountAmount ? discountAmount : amount,
      booking_type: authValue ? "immidiate" : "end_of_month",
    };

    addAssignment(spacesId, assignInfo)
      .then((data) => {
        setAssignShow(false);
        setSelectName("");
        setSelectEmail("");
        setSpacesId("");
        setMemberImage("");
        showNotifications(TOAST_TYPE.success, data.message);
      })
      .catch(() => {})
      .finally(() => {
        setAssignShow(false);
      });
  };

  return (
    <>
      <Modal
        show={assignShow}
        onHide={handleAssignClose}
        centered
        size='lg'
        className='assignModal'
      >
        <div className='addMemberForm'>
          <button className='closeModal' onClick={handleAssignClose}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <Container className='px-0'>
            <Row>
              <Col md={12}>
                <div className='addMemberHeading'>
                  <img src={assignmentIcon} alt='member' />
                  <p>Create Assignment</p>
                </div>
              </Col>
              <Col md={12}>
                <div className='memberSpaces'>
                  <div className='memberInfos assignBox'>
                    <div className='dropdown'>
                      <div onClick={(e) => { setIsActive(!isActive); }} className='dropdown-btn'>
                        <div className='d-flex tableImage'>
                          {selectName.length ? (
                            <>
                              {memberImage ? (
                                <img src={`${API}/${memberImage}`} alt='avatar' style={{ objectFit: "cover" }} />
                              ) : (
                                <img src={spaceIcon} alt='avatar' />
                              )}
                              <div>
                                <p>{selectName.length > 28 ? selectName.substring(0, 28) + "..." : selectName}</p>
                                <span>${rate}</span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                <p>Select Spaces</p>
                              </div>
                            </>
                          )}
                        </div>
                        <span>
                          <FontAwesomeIcon icon={faChevronDown} />
                        </span>
                      </div>
                      <div className='dropdown-content' style={{ display: isActive ? "block" : "none" }} >
                        <div className='assignInput px-2'>
                          <FontAwesomeIcon icon={faSearch} />
                          <input
                            type='text'
                            placeholder='Search member'
                            onChange={handleMemberChange}
                            className='form-control'
                          />
                        </div>
                        <div className='member-container'>
                          {filteredSpaces.map((data: any, index) => (
                            <div
                              key={`assignSpace` + index}
                              onClick={(e) => {
                                setIsActive(!isActive);
                                selectMember(data);
                              }}
                              className='item tableImage'
                            >
                              {data.space_image ? (
                                <img
                                  src={`${API}/${data.space_image}`}
                                  alt='avatar'
                                  style={{ objectFit: "cover" }}
                                />
                              ) : (
                                <img src={spaceIcon} alt='avatar' />
                              )}
                              <p>
                                {data.name.length > 17
                                  ? data.name.slice(0, 17) + "..."
                                  : data.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='arrowIcon mx-4'>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                  <div className='memberInfos assignBox tableImage'>
                    {spaceImage ? (
                      <img
                        src={`${API}/${spaceImage}`}
                        alt='avatar'
                        style={{ objectFit: "cover" }}
                      />
                    ) : (
                      <img className='default' src={memberIcon} alt='avatar' />
                    )}
                    <div>
                      <p>{name}</p>
                      <span>{maskEmail(email)}</span>
                    </div>
                  </div>
                </div>
                {spaceError &&
                <p className='mt-2 text-danger'>Please select a space.</p>
                }
              </Col>
              <Col md={12}>
                <div className='assignName'>
                  <div className='generateInvoice my-0 d-flex align-items-center'>
                    <h5 className='mb-0'>Invoice Due Today?</h5>
                    <div className='authToggle mt-0'>
                      {authValue === true ? (
                        <label className='switch'>
                          <input
                            type='checkbox'
                            onClick={authClick}
                            defaultChecked
                          />
                          <span className='slider round'></span>
                        </label>
                      ) : (
                        <label className='switch'>
                          <input type='checkbox' onClick={authClick} />
                          <span className='slider round'></span>
                        </label>
                      )}
                    </div>
                  </div>
                  {authValue ? (
                    <>
                      <div className='generateInvoice mt-4 mb-0'>
                        <h6>Amount Owed Today</h6>
                        <div className='memberInput amount'>
                          <span>$</span>
                          <input
                            type='text'
                            placeholder='Rate'
                            value={discountAmount}
                            onChange={(e) => setDiscountAmount(e.target.value)}
                            className='form-control'
                          />
                          <button className='calculate'>
                            (Automatically Prorated)
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className='invoiceSave mt-4'>
                  <button type='submit' onClick={bookSpace}>Save</button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Modal>
    </>
  );
};

export default AssignSpaces;
