import React from "react";
import { useStripe, useElements, CardElement, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { addPaymentMethod, stripeSetupIntent } from "../../api/settings";

import { Link } from "react-router-dom";

const options = {
    style: {
        base: {
            fontSize: "15px",
            fontWeight: 400,
            color: "rgba(54, 54, 55, 1)",
            letterSpacing: "0.025em",
            fontFamily: "inter",
            "::placeholder": {
                color: "rgba(116, 118, 121, 0.8)"
            }
        },
        invalid: {
            color: "rgba(54, 54, 55, 1)"
        }
    }
};
interface tabMemberProps {
    tabChoose: (tab: string, select: string) => void;
}

const PaymentCard = ({ tabChoose }: tabMemberProps) => {
    const stripe = useStripe();
    const elements = useElements();

    // const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(window.location.search);
    const token: any = urlParams.get("token");

    const handleSubmit = async (event: any) => {
        event.preventDefault();



        const stripeIntentData = await stripeSetupIntent();
      

        if (!stripeIntentData.client_secret) {
          throw new Error("STRIPE_CLIENT_SECRET is not defined");
        }
  
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (elements) {
            console.log("Setup Intent Error: 1");
            try {
                console.log("Setup Intent Error: 2");
                const cardElement = elements.getElement(CardNumberElement);
                console.log("Setup Intent Error: 3",cardElement);

                if (!cardElement) {
                    // Handle case where card element is not available
                    return;
                }
                console.log("Setup Intent Error: 3");

                const { setupIntent, error: setupError } = await stripe.confirmCardSetup(
                    stripeIntentData.client_secret,
                    {
                      payment_method: {
                        card: cardElement,
                      },
                    }
                  );
                  console.log("Setup Intent Error: 4");
                  if (setupError) {
                    console.log("Setup Intent Error:", setupError);

                  } else {
                    console.log("Setup Intent:", setupIntent);
            
                    const payload = {
                      payment_method: setupIntent.payment_method,
                      customer_id: stripeIntentData.customer_id
                    }
                    await addPaymentMethod(payload);
                    tabChoose("agreement", "billing");
                  }
            
            } catch (error) {
                console.error("Error creating payment method:", error);
            }
        }

    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="DemoWrapper">
                <div className="memberInput memberSignUp">
                    <label>Card Number</label>
                    <CardNumberElement options={options} />
                </div>
                <div className="cardNumber mt-0">
                    <div className="cardInput">
                        <label>Expiration Date</label>
                        <CardExpiryElement options={options} />
                    </div>
                    <div className="cardInput">
                        <label>CVV</label>
                        <CardCvcElement options={options} />
                    </div>
                </div>
                <div className="agreeCheck px-3 mt-3">
                    <label className="agreement">
                        <label className="tableCheckBox">
                            <div className="contactCheck">
                                <input type="checkbox" name="agreement" />
                                <span className="checkmark"></span></div>
                        </label>
                        <span className='agreeText'>I authorize Buzz Coworking to bill this card for my membership and any additional fees.</span>
                    </label>
                </div>
                <div className='profileSave'>
                <button
                    className='save'
                    onClick={handleSubmit}
                    disabled={!stripe}
                >
                    { "Save"}
                </button>
                </div>

            </form>
        </div>
    )
}

export default PaymentCard