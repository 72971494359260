import { get, post } from "./base-api";

// global editor
export const globalEditor = (body = {}) => {
    return post(`/globalEditor`, body, "multipart/form-data");
};
export const getGlobalEditor = () => {
    return get(`/getGlobalEditor`);
};
// contactSupport
export const contactSupport = (body = {}) => {
    return post(`/contactSupport`, body);
}
