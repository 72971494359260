import React, { useEffect, useState } from 'react';
import Layout from '../../Component/Layout/Layout';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import filter from "../../Assets/Images/icon/filter-lines.svg";
import { Table } from 'react-bootstrap';
import Pagination from '../../Component/Pagination/Pagination';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import editPen from "../../Assets/Images/icon/trash-02 (1).svg";
import MailUpload from './MailUpload';
import "./Mail.css";
import { mailDelete, mailList, singleMail } from '../../api/mailBox';
import ViewMail from './ViewMail';
import moment from 'moment';

const Mail = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [totalValue, setTotalValue] = useState<any>();
  const [limitValue, setLimitValue] = useState<any>();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const pageCount = Math.ceil(totalValue / limitValue);
  const [prevButton, setPrevButton] = useState<boolean>(false);
  const [nextButton, setNextButton] = useState<boolean>(false);
  const [pageValue, setPageValue] = useState<number>()
  const [mailUserList, setMailUserList] = useState([]);;
  const [uploadShow, setUploadShow] = useState(false);
  const handleUploadClose = () => setUploadShow(false);
  const [mailShow, setMailShow] = useState(false);
  const handleMailClose = () => setMailShow(false);
  const handleUploadShow = () => setUploadShow(true);
  const [mailInfo, setMailInfo] = useState({});;
  const company = localStorage.getItem("company");
  const userInfo = company ? JSON.parse(company) : null;
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSort = (columnName: string) => {
    if (sortBy === columnName) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnName);
      setSortOrder("asc");
    }
  };

  const nextPage = () => {
    setPage(page + 1);
    setNextButton(true);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  useEffect(() => {
    mailList().then((data) => {
      setMailUserList(data.results);
      // setMember(data.members);
      setTotalValue(data.total)
      setLimitValue(data.limit)
      setPageValue(data.page)
    }).catch((err) => { console.log(err) });
  }, [uploadShow, mailShow, count])

  const viewMail = (id: string) => {
    singleMail(id).then((data) => {
      setMailInfo(data)
      setMailShow(true);
    }).catch((err) => { console.log(err) });
  }


  const deleteMail = (id: string) => {
    mailDelete(id).then((data) => {
      setCount(count + 1)
    }).catch((err) => { console.log(err) });
  }

  return (
    <>
      <Layout>
        <div className='mainContent'>
          <div className='memberBox'>
            <div className='topLine'>
              <div className='tableHeading'>
                <h6>All Mail</h6>
              </div>
              <div className='memberSearch'>
                <div className='searchInput'>
                  <input type='text' placeholder='Search mail' onChange={handleInputChange} className='form-control' />
                  <FontAwesomeIcon icon={faSearch} />
                </div>
                <button className='filterBtn' style={{ marginLeft: "20px" }}>
                  <img className='mr-2' src={filter} alt='filter' /> Filter
                </button>
                {userInfo.user.role === "admin" ? <button onClick={handleUploadShow}>
                  <FontAwesomeIcon icon={faPlus} /> Upload Mail
                </button> : ""}
              </div>
            </div>
            <div className="mailSearch">
              <div className='searchInput w-100'>
                <input type='text' placeholder='Search mail' onChange={handleInputChange} className='form-control' />
                <FontAwesomeIcon icon={faSearch} />
              </div>
              {userInfo.user.role === "admin" ? <button onClick={handleUploadShow}>
                <FontAwesomeIcon icon={faPlus} />
              </button> : ""}
            </div>
            <div className='spaceList'>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>
                      <label className='tableCheckBox'>
                        <div className='contactCheck'>
                          <input type='checkbox' name='agreement' />
                          <span className='checkmark'></span>
                        </div>
                      </label>
                    </th>
                    <th
                      className='sortArrow'
                      onClick={() => handleSort("name")}
                    >
                      ID{" "}
                      {sortBy === "name" ? (
                        <>
                          {sortOrder === "asc" ? (
                            <FontAwesomeIcon icon={faArrowUp} />
                          ) : (
                            <FontAwesomeIcon icon={faArrowDown} />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </th>
                    <th
                      className='sortArrow'
                      onClick={() => handleSort("type")}
                    >
                      Uploaded{" "}
                      {sortBy === "type" ? (
                        <>
                          {sortOrder === "asc" ? (
                            <FontAwesomeIcon icon={faArrowUp} />
                          ) : (
                            <FontAwesomeIcon icon={faArrowDown} />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </th>
                    {userInfo.user.role === "admin" ? <th
                      className='sortArrow'
                      onClick={() => handleSort("rate")}
                    >
                      Recipient{" "}
                      {sortBy === "rate" ? (
                        <>
                          {sortOrder === "asc" ? (
                            <FontAwesomeIcon icon={faArrowUp} />
                          ) : (
                            <FontAwesomeIcon icon={faArrowDown} />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </th> : ""}
                    <th
                      className='sortArrow'
                      onClick={() => handleSort("status")}
                    >
                      Status{" "}
                      {sortBy === "status" ? (
                        <>
                          {sortOrder === "asc" ? (
                            <FontAwesomeIcon icon={faArrowUp} />
                          ) : (
                            <FontAwesomeIcon icon={faArrowDown} />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {mailUserList && mailUserList.map((mail: any, index: number) => <tr>
                    <td>
                      <label className='tableCheckBox'>
                        <div className='contactCheck'>
                          <input type='checkbox' name='agreement' />
                          <span className='checkmark'></span>
                        </div>
                      </label>
                    </td>
                    <td className='deskType'>M{mail.id}</td>
                    <td className='rate'>{moment(mail.created_at).format('MMMM D, YYYY')}</td>
                    {userInfo.user.role === "admin" ? <td className='status'>{mail.recipient}</td> : ""}
                    <td className='tableAction mailStatus'><span className={mail.status}>{mail.status === "pending" ? "Pending" : mail.status === "shredding" ? "Shredding Request" : mail.status === "shredded" ? "Shredded" : mail.status === "scanning" ? "Scanning Request" : mail.status === "scanned" ? "Scanned" : ""}</span></td>
                    <td className='tableAction'>
                      <button className='btn view' onClick={() => viewMail(mail.id)}>
                        <FontAwesomeIcon icon={faEye as any} />
                      </button>
                      {userInfo.user.role === "admin" ? <button className='btn edit' onClick={() => deleteMail(mail.id)}>
                        <img src={editPen} alt='edit' />
                      </button> : ""}
                    </td>
                  </tr>)}

                </tbody>
              </Table>
              <Pagination
                page={page}
                paginationTitle='items'
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                prevButton={prevButton}
                nextButton={nextButton}
                pageValue={pageValue}
                totalValue={totalValue}
                prevPage={prevPage}
                nextPage={nextPage}
                allRequestList={""}
              />
            </div>
          </div>
        </div>

        <MailUpload uploadShow={uploadShow} setUploadShow={setUploadShow} handleUploadClose={handleUploadClose} />
        <ViewMail mailInfo={mailInfo} mailShow={mailShow} setMailShow={setMailShow} handleMailClose={handleMailClose} />

      </Layout>
    </>
  )
}

export default Mail