import React, { useState, useRef, useEffect } from 'react';
import { Col, Container, Dropdown, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import resourceIcon from "../../Assets/Images/icon/resourceIcon.svg";
import changeLogo from "../../Assets/Images/icon/adminUser.png";
import { DESKIE_API as API } from '../../config';
import memberIcon from "../../Assets/Images/icon/memberLargeIcon.png";
import uploadIcon from "../../Assets/Images/icon/delete_svg.svg";
import deleteIcon from "../../Assets/Images/icon/upload_svg.svg";
import { resourceUpdate } from '../../api/resource';
import heic2any from 'heic2any';
import ReactQuill from 'react-quill';

interface AddResourcesProps {
    handleEditClose: () => void;
    editShow: boolean;
    setEditShow: (type: boolean) => void;
    editInfo: any;
}


const EditResource = ({ editInfo, editShow, setEditShow, handleEditClose }: AddResourcesProps) => {

    const [imageLogo, setImageLogo] = useState("");
    const [logoFile, setLogoFile] = useState("");
    const [uploadedLogo, setUploadedLogo] = useState("");
    const [userImage, setUserImage] = useState("");
    const [description, setDescription] = useState("");
    const [instruction, setInstruction] = useState("");
    const [name, setName] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [selectedCapacity, setSelectedCapacity] = useState("");
    const [memberTime, setMemberTime] = useState("");
    const [memberRate, setMemberRate] = useState("");
    const [publicTime, setPublicTime] = useState("");
    const [publicRate, setPublicRate] = useState("");
    const [authValue, setAuthValue] = useState(true);
    const [publicValue, setPublicValue] = useState(true);
    const [resourceOption, setResourceOption] = useState('overview');
    const [file, setFile] = useState("");
    const [imageKey, setImageKey] = useState("");
    const [memberImage, setMemberImage] = useState("");
    const [desValue, setDesValue] = useState(false);
    const [insValue, setInsValue] = useState(false);

    useEffect(() => {
        if (editInfo) {
            setName(editInfo.name);
            setSelectedType(editInfo.type);
            setSelectedCapacity(editInfo.capacity);
            setMemberTime(editInfo.member_time);
            setMemberRate(editInfo.member_rate);
            setPublicTime(editInfo.public_time);
            setPublicRate(editInfo.public_rate);
            setMemberImage(editInfo.image);
            if (editInfo.description) {
                setDescription(editInfo.description); 
                setDesValue(true);
            }
            if (editInfo.instruction) {
                setInstruction(editInfo.instruction);
                setInsValue(true);
            }
        }
    }, [editInfo]);


    const authClick = () => {
        setAuthValue(!authValue)
    }

    const publicClick = () => {
        setPublicValue(!publicValue)
    }

    const wrapperRef = useRef<HTMLInputElement>(null);
    async function onFileLogoDrop(event: any) {

        const file = event.target.files[0]
        if (file.type === 'image/heic' || file.type === 'image/heif') {
            try {
                const convertedBlob = await heic2any({
                    blob: file,
                    toType: 'image/jpeg',
                })
                setFile(URL.createObjectURL(convertedBlob as Blob))
                setImageKey(convertedBlob as any)
            } catch (error) {
                console.error('Error converting HEIC file:', error)
            }
        } else {
            setFile(URL.createObjectURL(file))
            setImageKey(file)
        }
    }
    const removeImage = () => {
        setFile("");
        setImageKey("");
        setMemberImage("");
        setImageLogo("logo");
    }

    const handleSelect = (eventKey: string | null) => {
        if (eventKey) {
            setSelectedType(eventKey);
        }
    };



    const handleMemberSelect = (eventKey: string | null) => {
        if (eventKey) {
            setMemberTime(eventKey);
        }
    };
    const handlePublicSelect = (eventKey: string | null) => {
        if (eventKey) {
            setPublicTime(eventKey);
        }
    };

    var modules: any = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] }
            ],
            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ]
    };

    var formats: any = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "align", "size",
    ];

    const handleDescriptionChange = (content: string) => {
        setDescription(content)
    };

    const handleInstructionChange = (content: string) => {
        setInstruction(content)
    };

    const descriptionClick = () => {
        setDesValue(!desValue)
    }

    const instructionClick = () => {
        setInsValue(!insValue)
    }

    const resourceCreate = () => {
        let resourceInfo: any = {
            "name": name,
            "type": selectedType,
            "capacity": selectedCapacity,
            "member_rate": memberRate,
            "member_time": memberTime,
            "public_rate": publicRate,
            "public_time": publicTime,
            "description": description,
            "instruction": instruction
        }
        if (imageKey) {
            resourceInfo["resource_image"] = imageKey;
        }
        resourceUpdate(editInfo.id, resourceInfo).then(() => {
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setEditShow(false);
        })

    }
    return (
        <>
            <Modal show={editShow} onHide={handleEditClose} centered size="xl">
                <div className="addMemberForm">
                    <button className='closeModal' onClick={handleEditClose}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className='addResourceHeading'>
                                    <div>
                                        <img src={resourceIcon} alt="member" />
                                        <p>Edit Resource</p>
                                    </div>
                                    <div className='resourceOption'>
                                        <button className={resourceOption === "overview" ? "active" : ""} onClick={() => setResourceOption("overview")}>Overview</button>
                                        <button className={resourceOption === "advanced" ? "active" : ""} onClick={() => setResourceOption("advanced")}>Advanced</button>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12}>
                                {resourceOption === "overview" ? <div className="resourceBox">
                                    <div className="profileImageUpload">
                                        <div className="profileTitle">
                                            <p>Resource Photo</p>
                                        </div>
                                        <div className="profileImgView d-flex justify-content-center">

                                            {file && file.length > 0 ? <img src={file} width="100px" height="100px" alt="shop" />
                                                : <>
                                                    {memberImage ? <div style={{ width: "80px", height: "80px", overflow: "hidden", borderRadius: "50%" }}>
                                                        <img src={`${API}/${memberImage}`} alt="avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                                    </div>
                                                        : <img src={memberIcon} width="100px" height="100px" alt="shop" />}
                                                </>
                                            }
                                        </div>
                                        <div className="profileFooter">
                                            <div ref={wrapperRef} className="drop-file-input">
                                                <div className="drop-file-input__label">
                                                    <img src={uploadIcon} className='uploadIcon' alt="delete" onClick={removeImage} />
                                                </div>
                                                <input type="file" onChange={onFileLogoDrop} />
                                            </div>

                                            <img src={deleteIcon} className='uploadIcon' alt="delete" onClick={removeImage} />
                                        </div>
                                    </div>
                                    <div className="resourceAdd">
                                        <div className="inputField resourceName">
                                            <span>Name</span>
                                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder='Enter your name' className='form-control' />
                                        </div>
                                        <div className='typeCapacity mt-4'>
                                            <div className="dropdownInput">
                                                <div className="dropdownField">
                                                    <span>Type</span>
                                                    <Dropdown onSelect={handleSelect}>
                                                        <Dropdown.Toggle variant="" className="custom-toggle">
                                                            {selectedType === "meeting" ? "Meeting Space" : selectedType === "equipment" ? "Equipment" : selectedType === "workspace" ? "Workspace" : selectedType === "other" ? "Other" : "Choose tag (type)"}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item eventKey="meeting">Meeting Space</Dropdown.Item>
                                                            <Dropdown.Item eventKey="equipment">Equipment</Dropdown.Item>
                                                            <Dropdown.Item eventKey="workspace">Workspace</Dropdown.Item>
                                                            <Dropdown.Item eventKey="other">Other</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <div className="inputSvg">
                                                        <FontAwesomeIcon icon={faSortDown} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dropdownInput">
                                                <div className="inputField resourceRate">
                                                    <span>Capacity</span>
                                                    <input type="number" value={selectedCapacity} onChange={(e) => setSelectedCapacity(e.target.value)} placeholder='Capacity' className='form-control' />
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rateChoose my-3">
                                            <div className="generateInvoice my-0">
                                                <h5 className='mb-0'>Allow member bookings?</h5>
                                                <div className="authToggle mt-0">
                                                    {authValue === true ?
                                                        <label className="switch">
                                                            <input type="checkbox" onClick={authClick} defaultChecked />
                                                            <span className="slider round"></span>
                                                        </label> :
                                                        <label className="switch">
                                                            <input type="checkbox" onClick={authClick} />
                                                            <span className="slider round"></span>
                                                        </label>}
                                                </div>
                                            </div>
                                            {authValue === true ?
                                                <div className="dropdownInput mt-4">
                                                    <div className="dropdownRate mt-3">
                                                        <span className='h-100 py-2'>Rate</span>
                                                        <div className='rateNumber'>
                                                            $<input className='form-control' value={memberRate} onChange={(e) => setMemberRate(e.target.value)} type='number' />
                                                        </div>

                                                        <div className="rateOption h-100 py-1">
                                                            <Dropdown onSelect={handleMemberSelect}>
                                                                <Dropdown.Toggle variant="" className="custom-toggle">
                                                                    {memberTime === "hour" ? "Per Hour" : memberTime === "day" ? "Per Day" : "Choose time"} <FontAwesomeIcon icon={faSortDown} />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item eventKey="hour">Per Hour</Dropdown.Item>
                                                                    <Dropdown.Item eventKey="day">Per Day</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}
                                        </div>

                                        <div className="rateChoose">
                                            <div className="generateInvoice my-0">
                                                <h5 className='mb-0'>Allow public bookings?</h5>
                                                <div className="authToggle mt-0">
                                                    {publicValue === true ?
                                                        <label className="switch">
                                                            <input type="checkbox" onClick={publicClick} defaultChecked />
                                                            <span className="slider round"></span>
                                                        </label> :
                                                        <label className="switch">
                                                            <input type="checkbox" onClick={publicClick} />
                                                            <span className="slider round"></span>
                                                        </label>}
                                                </div>
                                            </div>
                                            {publicValue === true ?
                                                <div className="dropdownInput mt-4">
                                                    <div className="dropdownRate mt-3">
                                                        <span className='h-100 py-2'>Rate</span>
                                                        <div className='rateNumber'>
                                                            $<input className='form-control' value={publicRate} onChange={(e) => setPublicRate(e.target.value)} type='number' />
                                                        </div>

                                                        <div className="rateOption h-100 py-1">
                                                            <Dropdown onSelect={handlePublicSelect}>
                                                                <Dropdown.Toggle variant="" className="custom-toggle">
                                                                    {publicTime === "hour" ? "Per Hour" : publicTime === "day" ? "Per Day" : "Choose time"} <FontAwesomeIcon icon={faSortDown} />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item eventKey="hour">Per Hour</Dropdown.Item>
                                                                    <Dropdown.Item eventKey="day">Per Day</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}
                                        </div>
                                        <div className="resourceBtn">
                                            <button onClick={resourceCreate}>Save</button>
                                        </div>
                                    </div>
                                </div> : <div className="resourceAdvanceBox">
                                    <div className="resourceDescription">
                                        <div className="generateInvoice">
                                            <h5 className='mb-0'>Add description?</h5>
                                            <div className="authToggle mt-0">
                                                {desValue === true ?
                                                    <label className="switch">
                                                        <input type="checkbox" onClick={descriptionClick} defaultChecked />
                                                        <span className="slider round"></span>
                                                    </label> :
                                                    <label className="switch">
                                                        <input type="checkbox" onClick={descriptionClick} />
                                                        <span className="slider round"></span>
                                                    </label>}
                                            </div>
                                        </div>
                                        {desValue === true ? <ReactQuill
                                            theme="snow"
                                            modules={modules}
                                            formats={formats}
                                            placeholder="Enter a description..."
                                            onChange={handleDescriptionChange}
                                            value={description}
                                        /> : ""}
                                    </div>
                                    <div className="resourceDescription mt-3">
                                        <div className="generateInvoice">
                                            <h5 className='mb-0'>Add custom instructions?</h5>
                                            <div className="authToggle mt-0">
                                                {insValue === true ?
                                                    <label className="switch">
                                                        <input type="checkbox" onClick={instructionClick} defaultChecked />
                                                        <span className="slider round"></span>
                                                    </label> :
                                                    <label className="switch">
                                                        <input type="checkbox" onClick={instructionClick} />
                                                        <span className="slider round"></span>
                                                    </label>}
                                            </div>
                                        </div>
                                        {insValue === true ? <ReactQuill
                                            theme="snow"
                                            modules={modules}
                                            formats={formats}
                                            placeholder="Enter a description..."
                                            onChange={handleInstructionChange}
                                            value={instruction}
                                        /> : ""}

                                    </div>
                                    <div className="resourceBtn">
                                            <button onClick={resourceCreate}>Save</button>
                                        </div>
                                </div>}

                            </Col>
                        </Row>
                    </Container>
                </div>
            </Modal>
        </>
    )
}

export default EditResource