import { useEffect, useState } from "react";
import { getKeys, keyUpdate } from "../../api/settings";
import { showNotifications, TOAST_TYPE } from "../../CommonFunction/toaster";

interface KeyValue {
  id?: string;
  value: string;
}

interface AllKeys {
  kisi_api_key?: KeyValue;
}

const Integration = () => {
  const [loading, setLoading] = useState(true);
  const [allKeys, setAllKeys] = useState<AllKeys>({});
  const [kisiApiKey, setKisiApiKey] = useState("");

  const fetchKeys = async () => {
    try {
      const keys = await getKeys();
      setAllKeys(keys);

      if(keys.kisi_api_key) {
        setKisiApiKey(keys.kisi_api_key.value);
      }
    } catch {}
    finally {
      setLoading(false);
    }
  };

  const updateKisiKey = async () => {
    try {
      const payload: {id?: string, key_name: string, value: string}  = {
        key_name: "kisi_api_key",
        value: kisiApiKey,
      };
      if(allKeys.kisi_api_key?.id){
        payload.id = allKeys.kisi_api_key.id;
      }

      const res = await keyUpdate(payload);
      showNotifications(TOAST_TYPE.success, res.message);
    } catch {}
  };

  useEffect(() => {
    fetchKeys();
  }, []);

  if(loading) {
    return (
      <div className="loaderWrapper mt-5 py-5">
        <span className='loaderBlue'></span>
      </div>
    )
  }

  return (
    <div>
        <div className="companyProfile">
          <div className='profileHeading'>
              <h6>Third Party Integration</h6>
              <p>Update third party keys here.</p>
          </div>
        </div>
        <div className="companyName">
            <p className="keyLabel">Kisi API Key</p>
            <input type="password" value={kisiApiKey} onChange={(e) => setKisiApiKey(e.target.value)} placeholder='Enter your Kisi API Key' className='keyInput' required />
            <button className='keySave' onClick={updateKisiKey}>Save</button>
        </div>
    </div>
  )
};

export default Integration;