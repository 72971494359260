import axios from "axios";
import logoutUser from "./logoutUser";
import { getMiddleWord } from '../CommonFunction/Function';
import { showNotifications, TOAST_TYPE } from "../CommonFunction/toaster";
import getCookie from "./getCookie";

export const API_ROOT = process.env.REACT_APP_DOCOTEAM_API_URL;

const http = (ContentType, baseURL = API_ROOT) => {
  let accessToken = false;
  const token = localStorage.getItem("token");
  // const { token } = JSON.parse(localStorage.getItem("company") || '{}');

  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = urlParams.get('token');

  if(token) {
    accessToken = token;
  } else if(tokenFromUrl) {
    accessToken = tokenFromUrl;
  };

  const headers = {
    "Content-Type": ContentType || "application/json",
    Accept: 'application/json',
    Authorization: accessToken? `Bearer ${accessToken}` : "",
    "x-database-name": getMiddleWord(window.location.hostname) || '',
  };

  const client = axios.create({
    baseURL,
    headers,
  });

  // Intercept response object and handleSuccess and Error Object
  function handleSuccess(response) {
    return response;
  }

  /**   Intercept any unauthorized request.
   *    status 401 means either accessToken is expired or invalid
   *    dispatch logout action accordingly     * */
  function handleError(error) {
    if(error.response.data && typeof error.response.data.message === 'string') {
      if(error.response.status !== 401) {
        showNotifications(TOAST_TYPE.error, error.response.data.message);
      }
    } else {
      showNotifications(TOAST_TYPE.error, "Something went wrong.");
    };

    if(error.config.url === '/auth/login'){
      logoutUser();
      return;
    }
    
    // Access token is expired or invalid
    if (error.response?.status === 401) {
      const refreshToken = getCookie("refreshToken");
      if (!refreshToken) {
        logoutUser();
        return Promise.reject(error.response.data);
      };

      // Refresh access token
      return axios
        .post(`${API_ROOT}/auth/token`, {
          refresh_token: refreshToken,
        },
        {
          headers: {
            "x-database-name": getMiddleWord(window.location.hostname) || '',
          },
        }
      )
        .then((response) => {
          const { token, token_expiry } = response.data;
          // Update the access token in the headers
          // client.defaults.headers["Authorization"] = `Bearer ${token}`;
          // const expirationTimeToken = new Date();
          // expirationTimeToken.setTime(
          //   expirationTimeToken.getTime() + token_expiry
          // );
          localStorage.setItem("token", token);
          // Cookies.set("token", token, { expires: expirationTimeToken });
          // Retry the original request with the new access token
          error.config.headers["x-database-name"] = getMiddleWord(window.location.hostname) || '';
          error.config.headers["Authorization"] = `Bearer ${token}`;
          return client(error.config);
        })
        .catch((refreshError) => {
          // Refresh token is expired or invalid, logout the user
          showNotifications(TOAST_TYPE.error, error.response.data.message);
          logoutUser();
          return Promise.reject(error.response.data);
        });
    }

    if (error.response?.status === 500) {
      return Promise.reject(error.response);
    }

    return Promise.reject(error.response.data);
  }

  client.interceptors.response.use(handleSuccess, handleError);

  function get(path) {
    return client.get(path).then((response) => response.data);
  }

  function post(path, payload) {
    return client.post(path, payload).then((response) => response.data);
  }

  function put(path, payload) {
    return client.put(path, payload).then((response) => response.data);
  }

  function patch(path, payload) {
    return client.patch(path, payload).then((response) => response.data);
  }

  function deleteApi(path, data) {
    if (data) {
      return client.delete(path, data).then((response) => response?.data);
    }
    return client.delete(path).then((response) => response.data);
  }

  return {
    get,
    post,
    put,
    patch,
    delete: deleteApi,
  };
};
export default http;