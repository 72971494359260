import React, { useEffect, useState } from 'react';
import { gapi } from 'gapi-script';


// const CLIENT_ID = '383860087875-dj9ramlrj2go7u2b6fa984eo4641boip.apps.googleusercontent.com';
// const API_KEY = 'AIzaSyClM2RLYrUtvd0rvlT8dVVRbTX6WCvYc-g'
// const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly';
// const PUBLIC_CALENDAR_ID = 'en.usa%23holiday%40group.v.calendar.google.com';
// const PUBLIC_CALENDAR_EMAIL = 'saidulislamsaif7890@gmail.com';
// const TEST_URL = `https://www.googleapis.com/calendar/v3/calendars/${PUBLIC_CALENDAR_EMAIL}/events?maxResults=1&key=${API_KEY}`;

interface CalendarEvent {
  id: string;
  summary: string;
  start: { dateTime?: string; date?: string };
  end: { dateTime?: string; date?: string };
}


const GoogleCalendar = () => {
  const CLIENT_ID = '43988075300-gp7o6qun0dst6qum1c2ur8rtmnro7l27.apps.googleusercontent.com'; // Replace with your CLIENT_ID
  const API_KEY = 'AIzaSyClM2RLYrUtvd0rvlT8dVVRbTX6WCvYc-g'; // Replace with your API Key
 // const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly';

  var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
  var SCOPES = "https://www.googleapis.com/auth/calendar.events"


  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);

  const loadGAPI = () => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.async = true;
    script.onload = () => {
      window.gapi.load('client:auth2', initClient); // Load the client and auth2 libraries
    };
    document.body.appendChild(script);
  };

  const initClient = () => {
    window.gapi.client.init({
      apiKey: 'AIzaSyAKxiZPjiAnrRwsNVaHhltZ0wgMopwpxPw', // API Key (non-authenticated requests)
      clientId: '43988075300-gp7o6qun0dst6qum1c2ur8rtmnro7l27.apps.googleusercontent.com', // OAuth client ID
      scope: 'https://www.googleapis.com/auth/calendar.readonly', // Scopes for the Calendar API
    }).then(() => {
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (!auth2.isSignedIn.get()) {
        auth2.signIn(); // Sign the user in if not already signed in
      } else {
        fetchEvents(); // Fetch events once signed in
      }
    }).catch((error: any) => {
      console.error('Error initializing Google API client:', error);
    });
  };

  const fetchEvents = async () => {
    try {
      const auth2 = window.gapi.auth2.getAuthInstance();
      const user = auth2.currentUser.get();
      const token = user.getAuthResponse().access_token;

      if (!token) {
        throw new Error('No access token found.');
      }

      const response = await fetch(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events?key=AIzaSyAKxiZPjiAnrRwsNVaHhltZ0wgMopwpxPw`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch events');
      }

      const data = await response.json();
      setEvents(data.items); // Store the events data
    } catch (err: any) {
      console.error('Error fetching events:', err);
    }
  };

  useEffect(() => {
    loadGAPI(); // Load the Google API script on component mount
  }, []);

  return (
    <div>
      <header className="App-header">
        <p>Click to add event to Google Calendar</p>
        <p style={{fontSize: 18}}>Uncomment the get events code to get events</p>
        <p style={{fontSize: 18}}>Don't forget to add your Client Id and Api key</p>
      </header>
    </div>
  )
}

export default GoogleCalendar