
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import "./Messenger.css";
import Layout from '../../Component/Layout/Layout';
import avatar from "../../Assets/Images/icon/blank-profile.jpg";
import assign from "../../Assets/Images/icon/memberAvatar.svg";
import emoji from "../../Assets/Images/icon/send.svg";
import fileShare from "../../Assets/Images/icon/fileshare.svg";
import onlineShow from "../../Assets/Images/icon/online.svg";
import more from "../../Assets/Images/icon/more.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import allMessage from "../../Assets/Images/icon/filter-lines.svg";
import FromMessage from './FromMessage';
import ToMessage from "./ToMessage"
import { SocketContext } from '../../CommonFunction/SocketContext';
import UploadFileForChat from '../../Component/UploadFile/UploadFileForChat';
import { CHATTER, USER } from '../../CommonFunction/types';
import { CHAT_GROUP, CHAT_SINGLE, CHAT_TEXT, GROUP_ICON } from '../../CommonFunction/constant';
import { chatSeen, getChatHistory, getChatters } from '../../api/message';
import { convertTimeToReadable, enhanceGroupUserName, enhanceToGroupUserName, formattedDateForChatHeadWithCurrent, fromNow, messageFormatDate } from "../../CommonFunction/Function";
import UsersList from '../../Component/Messenger/UserList';
import { getUsers } from '../../api/admin';
import { DESKIE_API as API } from '../../config';
import groupImage from "../../Assets/Images/icon/group.png";
import plusMobile from "../../Assets/Images/chatMobile/plusMobile.png"
import threeLine from "../../Assets/Images/chatMobile/threeLine.svg"
import searchMobile from "../../Assets/Images/chatMobile/searchMobile.svg"

const initUser = {
    email: "",
    name: "",
    avatar: "",
    role: "",
    online_status: 0,
}

interface MSG {
    sender: string,
    recipient: string,
    message: string,
    type: boolean,
    mode: boolean,
    file_path: string,
    created_at: string,
}

const Messenger = () => {
    const dropdownContent = useRef<HTMLDivElement>(null);
    const [messages, setMessages] = useState<MSG[]>([]);
    const [input, setInput] = useState<string>('');
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const [users, setUsers] = useState<CHATTER[]>([]);
    console.log('users',users);
    
    const [toUsers, setToUsers] = useState<USER[]>([]) // users to be showed in the dropdown
    const [dropdownUsers, setDropdownUsers] = useState<USER[]>([])
    const [currentUser, setCurrentUser] = useState<USER>(initUser)
    const [chatter, setChatter] = useState<CHATTER | null>(null) // chatter  
    const [to, setTo] = useState<USER[]>([]) // users to be showed in the "To" menu
    const [mobileChatBox, setMobileChatBox] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)
    const [messageList, setMessageList] = useState(true);
    const [messageBox, setMessageBox] = useState(true);
    const [uploadShow, setUploadShow] = useState(false);
    const handleUploadClose = () => setUploadShow(false);
    const [showMobileMessages, setShowMobileMessages] = useState(true);


    const navigate = useNavigate()
    const socket = useContext(SocketContext);
    // const socket = useRef<Socket | null>(null);

    // handle to show the dropdown 
    const closeDropdown = (e: MouseEvent) => {
        if (dropdownContent.current && !dropdownContent.current.contains(e.target as Node)) {
            setShowDropdown(false)
        }
    }

    // handle to click the to users item
    const handleToUser = (user: USER) => {
        setDropdownUsers((prev) => prev.filter((e, i) => e !== user))
        setTo((prev) => [...prev, user])
    }

    const handleMobileToUser = (user: any) => {
        setTo((prev) => {
            // Check if the user is already in the list by comparing the email field
            const userExists = prev.some((u: any) => u.email === user.email);

            // If the user exists, remove them; otherwise, add them
            return userExists
                ? prev.filter((u: any) => u.email !== user.email) // Remove the user
                : [...prev, user]; // Add the new user
        });
    }

    // handle to click cancel of the to users
    const handleCancelToUsers = (user: USER) => {
        setDropdownUsers((prev) => [...prev, user])
        setTo((prev) => prev.filter((e, i) => e !== user))
    }

    useEffect(() => {
        document.addEventListener("mousedown", closeDropdown)
        return () => {
            document.removeEventListener("mousedown", closeDropdown)
        }
    }, [])

    useEffect(() => {
        scrollToBottom();
    }, [messages, input]);

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    const messageStart = () => {
        if (!input.trim()) return

        if (to.length === 0) {
            alert("Please select the receiver")
            return
        }
        // setChatMessage(prevMessages => [...prevMessages, input]);
        const sockData = {
            sender: currentUser.email,
            name: currentUser.name,
            // reciever: from.email,
            recipient: to,
            mode: CHAT_TEXT,
            type: to.length > 1 ? CHAT_GROUP : CHAT_SINGLE,
            message: input,
            group_id: chatter?.group_id,
            created_at: new Date()
        }

        socket?.emit("senderMessage", sockData)
        // if (to.length > 1) handleFromSet(null)
        setInput('');
    }

    // handle for clicking the "Enter"
    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {

        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault()
            messageStart()
        }
    }


    // handle to click the receivers
    const handleFromSet = (e: CHATTER | null) => {
console.log('seen',e);

        if (window.innerWidth <= 800) {
            setMessageList(true);
            setMessageBox(false);
        }

        if (e) {
            setChatter(e)
            const user = { email: e.recipient, avatar: e.avatar, name: e.name, role: e.role, online_status: 1 }
            setTo([user])
            chatSeen(e.recipient,e.sender,e.role)
            // setToUsers(users.filter((user, i) => user !== e))
        } else {
            setChatter(null)
            setMessages([])
            setTo([])

            setDropdownUsers(toUsers)
            if (window.innerWidth >= 800) {
                setShowDropdown(true)
            }
            else {
                setMobileChatBox(true);
            }
        }
        // bring the chat history & set the message pool
    }

    const updateContacts = async () => {
        try {
            const company = localStorage.getItem("company")
            if (!!company) {
                const storage = await JSON.parse(company)
                // set chatters that have chatted before
                const chatters = await getChatters(storage.user.email)
                setUsers(chatters)

                // set users that can send msg
                let chattersEmail: any[] = []
                if (chatters.length > 0) {
                    chattersEmail = chatters.map((e: any, i: number) => e.recipient)
                }
                const tempUsers = await getUsers()
                let tusers: any[] = []
                if (tempUsers.length > 0) {
                    tusers = tempUsers.filter((e: any, i: number) => (e.email !== storage.user.email) && !chattersEmail.includes(e.email))
                }
                setToUsers(tusers)
            }
        } catch (err) { console.log(err) }
    }

    const handleToReceiveSocket = async (payload: any) => {
        const single = chatter && !payload.group_id && chatter.recipient === payload.sender
        const group = chatter && payload.group_id && chatter.group_id === payload.group_id
        if (single || group) {
            setMessages((prev) => [...prev, payload])
        }
        await updateContacts()
    }

    const handleToSenderSocket = async (payload: any) => {
        if (!chatter) {
            const tchatter = payload as CHATTER
            const chatter = { ...tchatter, avatar: payload.avatar ? payload.avatar : GROUP_ICON }
            const tuser = {
                email: payload.recipient,
                name: payload.name ? payload.name : payload.group_name,
                role: payload.role,
                group_id: payload.group_id,
                online_status: 1,
                avatar: payload.avatar ? payload.avatar : GROUP_ICON
            }
            setChatter(chatter)
            setTo([tuser])
        }
        updateContacts()
        setMessages((prev) => [...prev, payload])
        // await updateContacts()
    }

    useEffect(() => {
        socket.off("toSender");
        socket.off("toReciever");

        socket.on("toSender", async (payload) => {
            await handleToSenderSocket(payload)
        });

        socket.on("toReciever", async (payload: any) => {
            await handleToReceiveSocket(payload)
        });

        if (!chatter) return;

        const fetchData = async () => {
            try {
                let overload = {
                    from: chatter?.sender,
                    to: chatter?.recipient,
                    group_id: chatter?.group_id
                }
                const payload = await getChatHistory(overload)
                // setMessages((prev)=>[...prev, payload.data])
                setMessages(payload.data);
            } catch (err) { console.log(err) }
        }
        fetchData();
    }, [chatter])

    useEffect(() => {
        async function fetchData() {
            const company = localStorage.getItem("company")
            if (!!company) {
                const storage = await JSON.parse(company)
                setCurrentUser(storage.user);

                await updateContacts()

                socket.emit("userLogin", storage.user);

                socket.on("online", (payload: any) => {
                    socket?.emit("changeOnlineStatus", { email: payload.email })
                });

                socket.on("userOnline", async (payload: any) => {
                    await updateContacts()
                });

                socket.on("userOffline", async (payload: any) => {
                    await updateContacts()
                });

                socket.on("toReciever", async (payload: any) => {
                    await handleToReceiveSocket(payload)
                });

                socket.on("toSender", async (payload) => {
                    await handleToSenderSocket(payload)
                });

            } else {

                navigate("/login");
                return
            }
        }
        fetchData();
    }, [])


    const chatOff = () => {
        setMessageList(false);
        setMessageBox(true);
    }

    const chatListOff = () => {
        setMobileChatBox(false);
        setMessageList(false);
        setMessageBox(true);
    }

    const chatCreate = () => {
        setMobileChatBox(false);
        setMessageList(true);
        setMessageBox(false);
    }

    return (
        <Layout>
            <div className='mainContent'>
                {showMobileMessages && (<div className="all-messages-parent-mobile">
                    <p >My Messages</p>
                    <div style={{ display: "flex", alignItems: "center", }}>
                        <div className="all-messages-mobile" style={{ width: "100%", marginBottom: "4px" }}>
                            <input type="text" placeholder='Search messages' />
                            <img src={searchMobile} alt="searchMobile" />
                        </div>
                        <img src={threeLine} alt="threeLine" style={{ height: "48px", marginLeft: "10px" }} />
                        <div className="button" onClick={() => handleFromSet(null)} style={{ display: "flex", gap: "10px", marginLeft: "10px", marginBottom: "4px" }}>
                            <img src={plusMobile} style={{ height: "43px" }} />
                        </div>
                    </div>
                </div>)}
                {messageBox ? <>
                    <div className='chatHeading'>
                                <h6>My Messages</h6>
                            </div>
                    <div className="mobileFilesHeader mb-3">
                    <div className='searchInput'>
                        <input type="text" placeholder='Search files' className='form-control' />
                        <FontAwesomeIcon icon={faSearch} />
                    </div>
                    <button onClick={() => handleFromSet(null)} className='uploadIcon'><FontAwesomeIcon icon={faPlus} /></button>
                </div>
                </>
                 : ""}
                <div className="chat">
                    {messageBox ? <div className="contacts">
                        <div className="all-messages-parent">
                            <div className="all-messages">
                                {/* <img className="info-circle-icon" alt="" src={allMessage} />   */}
                                <p>All Messages</p>
                            </div>
                            <div className="button" onClick={() => handleFromSet(null)}>
                                <FontAwesomeIcon icon={faPlus} />
                            </div>
                        </div>

                        <div className="contact contact-mobile">
                            {showDropdown ? <div className="contact1 chatter-select" onClick={() => handleFromSet(null)}>
                                <div className="avatar-parent">
                                    <img className="default" alt="" src={assign} />
                                    <div className="bogdan-krivenchenko">New Message</div>
                                </div>
                            </div> : ""}
                            {/*  */}
                            {users.length > 0 && users?.map((e, i) =>
                            (<div className={"chatList " + ((!e.group_id && (e.recipient === chatter?.recipient)) || (e.group_id && (e.group_id === chatter?.group_id)) ? "chatter-select" : "")} key={i}>
                                {/* <>{e.group_id}</> */}
                                <div className="chatBox"
                                    onClick={() => handleFromSet(e)}>
                                    <div className="avatar">
                                        {/* <img className="avatar-icon1" alt="" src={`${API}/image/avatar/${e.avatar}`} /> */}
                                        {e.avatar ? <img className="avatar-icon1" alt="" src={`${API}/${e.avatar}`} />
                                            : <img className="avatar-icon1" alt="" src={avatar} />}

                                        {e.online_status ? (<div className="avatar-online-indicator">
                                            <img alt="" src={onlineShow} />
                                        </div>) : ""}
                                    </div>
                                    <div className="text">
                                        <div className="bogdan-krivenchenko">{e.group_id ? enhanceGroupUserName(e.name!) : e.name}</div>
                                        <div className="hi-how-are">
                                            {e.message?.length > 25 ? `${e.message.substring(0, 25)}...` : e.message}
                                        </div>
                                    </div>
                                </div>
                                <div className="parent">
                                    <div className="div16">{messageFormatDate(e.created_at)}</div>
                                    {/* {e.admin_seen ? <div className="ellipse" /> : ""}  */}
                                    {e.message_seen ? "user seen" : "user unseen" }
                                 

                                </div>
                            </div>)
                            )}
                        </div>
                    </div> : ""}

                    {messageList ? <div className="chat1">
                        <div className="user-header dropdown align-content-center">
                            {showDropdown ?
                                <div className="dropdown-content" ref={dropdownContent}>
                                    {dropdownUsers?.map((e, i) => ( // dropdown
                                        <div className='item w-100' onClick={() => handleToUser(e)}>
                                            {e.avatar ? <img className='avatar-icon1' src={`${API}/${e.avatar}`} alt='' width={"48px"} />
                                                : <img className='avatar-icon1' src={avatar} alt='' width={"48px"} />
                                            }

                                            <div className='label'>{e.name}</div>
                                        </div>
                                    ))}
                                </div> : <></>}
                            {to.length === 1 ? to.map((e, i) => (
                                <div className="user-parent">
                                    <div className="user">
                                        <div className="avatar-parent6"> <span className='chatBack' onClick={chatOff}><FontAwesomeIcon icon={faArrowLeft} /></span>
                                            <div className="avatar1">
                                                {e.avatar ? <img className="avatar-icon1" alt="" src={`${API}/${e.avatar}`} />
                                                    : <img className="avatar-icon1" alt="" src={avatar} />
                                                }
                                                {/* <div className="avatar-online-indicator">
                                                    <img alt="" src={"onlineShow"} />
                                                </div> */}
                                            </div>
                                            <div className="dropdown">
                                                <div className="sarah-kline">{e.name && e.role === "group" ? enhanceToGroupUserName(e.name) : e.name}</div>
                                                <div className="member">{e.role === "user" ? "Member" : "Group"}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <img className="more-icon" alt="" src={more} /> */}
                                </div>)) : (
                                <div className="div-1"> <span className='chatBack' onClick={chatOff}><FontAwesomeIcon icon={faArrowLeft} /></span>
                                    <div className="div-2">To:
                                    </div>

                                    {to ? to.map((e, i) => (<div className="div-3">
                                        {e.avatar ? <img className="img" alt="" src={`${API}/${e.avatar}`} />
                                            : <img className="img" alt="" src={avatar} />
                                        }
                                        <div className="div-4">{e.name}</div>
                                        <img
                                            onClick={() => handleCancelToUsers(e)}
                                            alt=''
                                            // loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/c96975ae083222964c83f8a433cc705fcd29254bae86a76c45c56676067976ea?"
                                            className="img-2"
                                        />
                                    </div>)) : (<></>)}

                                </div>
                            )}

                        </div>
                        <div className="chat2" >
                            <div className="friday-january-26th-parent" ref={chatContainerRef}>
                                {messages.length ? <></> : <div className="friday-january-26th">{formattedDateForChatHeadWithCurrent()}</div>}
                                {!chatter ? <UsersList to={to} /> : <></>}

                                {chatter ? messages.map((e, i) => {
                                    return e.sender === currentUser.email ? <ToMessage key={i} {...e} online_status={true} avatar={currentUser.avatar} /> :
                                        <FromMessage key={i} {...e} online_status={chatter?.online_status} avatar={chatter?.avatar} />
                                }) : <></>}
                            </div>
                            <div className="avatar-parent7">
                                {/* <img className="avatar-icon" alt="" src={`${API}/image/avatar/${currentUser.avatar}`} /> */}

                                {currentUser.avatar ? <img className="avatar-icon" alt="" src={`${API}/${currentUser.avatar}`} />
                                    : <img className="avatar-icon" alt="" src={avatar} />
                                }
                                <div className="input-field">
                                    <div className="input-with-label">
                                        <div className="label1">Email Address</div>
                                        <div className="input">
                                            <img
                                                className="search-md-icon"
                                                alt=""
                                                src="/searchmd.svg"
                                            />
                                            <div className="content3">
                                                <textarea className="text9 form-control"
                                                    value={input}
                                                    onKeyDown={handleKeyPress}
                                                    onChange={(e) => setInput(e.target.value)}
                                                    placeholder='Write your message' />
                                            </div>
                                            <img
                                                onClick={() => setUploadShow(true)}
                                                className="info-circle-icon"
                                                alt=""
                                                src={emoji}
                                            />
                                            <img
                                                className="info-circle-icon"
                                                alt=""
                                                onClick={messageStart}
                                                src={fileShare}
                                            />
                                        </div>
                                    </div>
                                    <div className="hint-text">
                                        This is a hint text to help user.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ""}

                    {mobileChatBox ? <div className="mobileNewMessage">
                        <div className="mobileMessageHead">
                            <span className='chatBack' onClick={chatListOff}><FontAwesomeIcon icon={faArrowLeft} /></span>
                            <p>New Message</p>
                            <img src={more} alt="more" />
                        </div>

                        <div className="mobileMessengerList">
                            {to.length ? <div className="mobileToUser">
                                <span>To:</span> {to ? to.map((e, i) => (<div className="div-3">
                                    {e.avatar ? <img className="img" alt="" src={`${API}/${e.avatar}`} />
                                        : <img className="img" alt="" src={avatar} />
                                    }
                                    <div className="div-4">{e.name}</div>
                                    <FontAwesomeIcon onClick={() => handleCancelToUsers(e)} icon={faXmark} />
                                    
                                </div>)) : (<></>)}
                            </div> : ""}

                            <h6>Select: </h6>
                            {dropdownUsers?.map((e, i) => (<div className='mobileChat'>
                                <div className='chatItem'>
                                    {e.avatar ? <img className='avatar-icon1' src={`${API}/${e.avatar}`} alt='' width={"48px"} />
                                        : <img className='avatar-icon1' src={avatar} alt='' width={"48px"} />
                                    }

                                    <p>{e.name}</p>
                                </div>
                                <label className="tableCheckBox">
                                    <div className="contactCheck">
                                        <input type="checkbox" name="agreement" onClick={() => handleMobileToUser(e)} />
                                        <span className="checkmark"></span></div>
                                </label>
                            </div>
                            ))}
                            <button className='mobileCreate' onClick={chatCreate}>Create Chat</button>
                        </div>
                    </div> : ""}


                </div>
            </div>
            <UploadFileForChat chatter={chatter} from={currentUser} to={to} uploadShow={uploadShow} setUploadShow={setUploadShow} handleUploadClose={handleUploadClose} />
        </Layout>
    )
}

export default Messenger