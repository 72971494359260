import React, { useRef, useState, useEffect } from 'react'
import trash from '../../Assets/Images/icon/trash-02 (1).svg'
import changeLogo from '../../Assets/Images/icon/memberLargeIcon.png'
import uploadFile from '../../Assets/Images/icon/uploadIcon.svg'
import PhoneInput from 'react-phone-input-2'
import { singleJwtMember } from '../../api/member'
import { isAuthenticate } from '../../api/auth'
import { DESKIE_API as API } from '../../config'
import { updateAdmin } from '../../api/admin'
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import ConfirmationModal from '../../Component/ConfirmationModal/ConfirmationModal'
import ReactQuill from 'react-quill'
import { agreementCreate, getAgreement } from '../../api/agreement'


const Membership = () => {

  const [agreementId, setAgreementId] = useState('')
  const [confirmationShow, setConfirmationShow] = useState(false)
  const [content, setContent] = useState('')
  const [emailContent, setEmailContent] = useState('')
  const [welcomeEmail, setWelcomeEmail] = useState(false);

  useEffect(() => {
    getAgreement().then((data) => {
      setContent(data.data.agreement)
      if (data.data.welcome_email) {
        setEmailContent(data.data.welcome_email)
        setWelcomeEmail(true);
      }
      setAgreementId(data.data.id)
    }).catch((err) => { console.log(err) });
  }, [])

  const adminUpdate = () => {
    let agreementInfo = {
      id: agreementId ? agreementId : uuidv4(),
      agreement: content,
      welcome_email: emailContent
    }
    agreementCreate(agreementInfo).then((data) => {
      showNotifications(TOAST_TYPE.success, data.message)
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setConfirmationShow(false)
    })
  }


  var modules: any = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
        { align: [] },
      ],
      [
        {
          color: [
            '#000000',
            '#e60000',
            '#ff9900',
            '#ffff00',
            '#008a00',
            '#0066cc',
            '#9933ff',
            '#ffffff',
            '#facccc',
            '#ffebcc',
            '#ffffcc',
            '#cce8cc',
            '#cce0f5',
            '#ebd6ff',
            '#bbbbbb',
            '#f06666',
            '#ffc266',
            '#ffff66',
            '#66b966',
            '#66a3e0',
            '#c285ff',
            '#888888',
            '#a10000',
            '#b26b00',
            '#b2b200',
            '#006100',
            '#0047b2',
            '#6b24b2',
            '#444444',
            '#5c0000',
            '#663d00',
            '#666600',
            '#003700',
            '#002966',
            '#3d1466',
            'custom-color',
          ],
        },
      ],
    ],
  }
  var formats: any = [
    'header',
    'height',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'color',
    'bullet',
    'indent',
    'link',
    'align',
    'size',
  ]
  const handleProcedureContentChange = (content: string) => {
    setContent(content)
  }

  const handleEmailChange = (content: string) => {
    setEmailContent(content)
  }

 

  const welcomeClick = () => {
    setWelcomeEmail(!welcomeEmail)
  }

  return (
    <>
      <div>
        <div className='companyProfile'>
          <div className='profileHeading'>
            <h6>Onboarding</h6>
            <p>
              Settings pertaining to your members’ account creation/setup.
            </p>
          </div>
          <div className='profileSave'>
            <button className='cancel'>Cancel</button>
            <button className='save' onClick={() => setConfirmationShow(true)}>Save</button>
          </div>
        </div>
        <div className="resourceDescription mt-4">
          <div className="generateInvoice">
            <h5 className='mb-0'>Membership Agreement</h5>
          </div>
          <ReactQuill
            theme='snow'
            modules={modules}
            formats={formats}
            placeholder='Enter a description...'
            onChange={handleProcedureContentChange}
            value={content}
          />

        </div>
        <div className="resourceDescription mt-4">
          <div className="generateInvoice">
            <h5 className='mb-0'>Add custom instructions to the account setup/welcome email?</h5>
            <div className="authToggle mt-0">
              {welcomeEmail === true ?
                <label className="switch">
                  <input type="checkbox" onClick={welcomeClick} defaultChecked />
                  <span className="slider round"></span>
                </label> :
                <label className="switch">
                  <input type="checkbox" onClick={welcomeClick} />
                  <span className="slider round"></span>
                </label>}
            </div>
          </div>
          {welcomeEmail === true ? <ReactQuill
            theme='snow'
            modules={modules}
            formats={formats}
            placeholder='Enter a description...'
            onChange={handleEmailChange}
            value={emailContent}
          /> : ""}

        </div>
      </div>
      <ConfirmationModal
        ConfirmationShow={confirmationShow}
        afterConfirmationApi={adminUpdate}
        handleConfirmationClose={() => setConfirmationShow(false)}
      />
    </>
  )
}

export default Membership
