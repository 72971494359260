import { del, get, post, put } from "./base-api";


// ticket add
export const ticketCreate = (body = {}) => {
    return post(`/ticketCreate`, body, 'multipart/form-data');
};

// ticket list
export const ticketList = (limit: number, page: number, ticketTag: string, memberName?: string) => {
    const memberNameParam = memberName ? `&member_name=${memberName}` : '';
    return get(`/ticketList?limit=${limit}&page=${page}&ticketTag=${ticketTag}${memberNameParam}`);
};

// ticket view
export const ticketView = (id: string) => {
    return get(`/ticketView/${id}`);
};
// ticket update
export const ticketUpdate = (id: string) => {
    return put(`/ticketUpdate/${id}`);
};