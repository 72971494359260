import React, { useState, useEffect } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import dots from "../../../Assets/Images/icon/dots-vertical_black.svg";
import moment from 'moment';
import { convertBytesToSize } from '../../../CommonFunction/Function';
import memberIcon from "../../../Assets/Images/icon/memberAvatar.svg";
import { DESKIE_API as API } from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import filter from '../../../Assets/Images/icon/filter-lines.svg';
import Pagination from '../../../Component/Pagination/Pagination';

interface FilesProps {
    handleClose: () => void;
    show: boolean;
    setShow: (type: boolean) => void;
    filteredFiles: any;
    getFileType: any;
    shareModal: any;
    fileUpload: any;
    setSearchTerm: any;
    page: any;
    setPage: any;
    limit: any;
    setLimit: any;
    prevButton: any;
    nextButton: any;
    pageValue: any;
    totalValue: any;
    prevPage: any;
    nextPage: any;
    filesList: any;
};

const MobileFiles = ({ show, setShow, handleClose, filteredFiles, shareModal, getFileType, fileUpload, setSearchTerm, page, setPage, limit, setLimit, prevButton, nextButton, pageValue, totalValue, prevPage, nextPage,filesList }: FilesProps) => {
    const [filterTag, setFilterTag] = useState('');

    return (
        <Modal show={show} onHide={handleClose} centered size="lg" className='mobileFilesModal'>
            <h1 className='filesHeading'><span onClick={handleClose} className="icon"><FontAwesomeIcon icon={faArrowLeft} /></span> Files</h1>
            <div className="filesContent">
            <div className='mobileFilesHeading mt-2'>
                <h6>All Files</h6>
            </div>
            <div className="mobileFilesHeader mb-3">
                <div className='searchInput'>
                    <input type="text" placeholder='Search files' onChange={(e) => setSearchTerm(e.target.value)} className='form-control' />
                    <FontAwesomeIcon icon={faSearch} />
                </div>
                <div className='filterDropdown taskDropdown'>
                    <Dropdown>
                        <Dropdown.Toggle>
                            <button className='filterBtn'><img className='mr-2' src={filter} alt='filter' />{filterTag === "created" ? "My Files" : filterTag === "member" ? "Shared With Me" : filterTag === "all" ? "All Files" : ""}</button>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setFilterTag('all')}>All Files</Dropdown.Item>
                            <Dropdown.Item onClick={() => setFilterTag('created')}>My Files</Dropdown.Item>
                            <Dropdown.Item onClick={() => setFilterTag('member')}>Shared With Me</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <button onClick={() => fileUpload()} className='uploadIcon'><FontAwesomeIcon icon={faPlus} /></button>
            </div>
            {filteredFiles && filteredFiles.map((file: any, index: number) =>
                <div className="mobileFile">
                    <div className='fileBox'>
                        <div className="filesIcon">
                            <img src={getFileType(file.extension)} alt="avatar" />
                        </div>
                        <div className="filesDetails">
                            <h5>{file.nick_name}</h5>
                            <p><span className='date'>{moment(file.created_at).format('MMMM D, YYYY')}</span> <span className='fileSize'>{convertBytesToSize(file.size)}</span></p>
                        </div>
                        <div className="filesDropdown">
                            <img src={dots} alt="dots" />
                        </div>
                    </div>
                    <div className="filesShareBtn">
                        <div className="avatars2">
                            {file.sharesList.map((share: any, i: number) =>
                                <div key={`myShare` + i}>{share.image === "imageNull" ? <img className="avatar-icon36 default" alt="" src={memberIcon} />
                                    : <img className="avatar-icon36" alt="" src={`${API}/${share.image}`} />
                                }</div>
                            )}
                            {file.delete ?
                                <div className="avatar2" onClick={() => shareModal(file.id, file.shares)}>
                                    +
                                </div>
                                : ""}
                        </div>
                    </div>
                </div>)}
            </div>
            <Pagination page={page} paginationTitle="files" setPage={setPage} limit={limit} setLimit={setLimit} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} allRequestList={filesList} />
        </Modal>
    )
}

export default MobileFiles