import { get, getJwt, post, put } from "./base-api";

// admin login
export const memberAdd = (body = {}) => {
  return post("/memberCreate", body, "multipart/form-data");
};

export const createMember = (body = {}) => {
  return post("/public/member/create", body, "multipart/form-data");
};

// member list
export const getMemberList = (limit: number, page: number) => {
  return get(`/memberList?limit=${limit}&page=${page}`);
};

// all member list
export const allMemberList = (limit: number, page: number, search: string) => {
  return get(`/allMemberList?limit=${limit}&page=${page}&search=${search}`);
};

// single member
export const singleMember = (id: string) => {
  return get(`/memberSingle/${id}`);
};

// update member info
export const updateMember = (id: string, body = {}) => {
  return put(`/editMember/${id}`, body, "multipart/form-data");
};

// member list search
export const searchMember = (id: string) => {
  return get(`/memberSearch/${id}`);
};
// member single
export const singleJwtMember = () => {
  return get("/jwtMemberSingle");
};
// signUpMember
export const signUpMember = (token: string) => {
  return getJwt("/signUpMember", token);
};

// update signature
export const updateSignature = (id: string, body = {}, token: string) => {
  return put(`/signatureAdd/${id}`, body, "multipart/form-data");
};

export const memberSpaces = (id: string, limit: number|undefined = undefined, page: number|undefined = undefined) => {
  return get(`/singleMemberList?memberId=${id}&limit=${limit}&page=${page}`);
};

export const memberInvoice = (id: string, limit: number|undefined = undefined, page: number|undefined = undefined) => {
  return get(`/singleMemberInvoice?limit=${limit}&page=${page}&memberId=${id}`);
};

// forget password
export const forgetPassword = (body = {}) => {
  return post(`/forgetPassword`, body);
};

// update password
export const forgetUpdate = (id: string, body = {}) => {
  return put(`/forgetUpdate/${id}`, body);
};

// member active
export const memberActive = async (body = {}) => {
  return put(`/memberActive`, body);
};
// reminder member
export const reminderMember = (body = {}) => {
  return post(`/reminderMember`, body);
};

export const memberMe = () => {
  return get("/member/me");
}
