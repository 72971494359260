import React, { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import folder from "../../Assets/Images/icon/folder.svg";
import uploadFile from "../../Assets/Images/icon/uploadIcon.svg";
import { DESKIE_API as API } from '../../config';
import downloadIcon from "../../Assets/Images/icon/download-01.svg";
import fileFormat from "../../Assets/Images/icon/mail-03.svg";
import { convertBytesToSize } from '../../CommonFunction/Function';
import { mailStatus } from '../../api/mailBox';
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';
import trash from "../../Assets/Images/icon/trash-02.svg";

interface MailFileProps {
    handleMailClose: () => void;
    mailShow: boolean;
    setMailShow: (type: boolean) => void;
    mailInfo: any;
}
const ViewMail = ({ mailInfo, mailShow, setMailShow, handleMailClose }: MailFileProps) => {
    const wrapperRef = useRef<HTMLInputElement>(null);
    const [interiorFiles, setInteriorFiles] = useState<File[]>([]);
    const [interiorName, setInteriorName] = useState("");
    const [interiorFile, setInteriorFile] = useState("");
    const company = localStorage.getItem("company");
    const userInfo = company ? JSON.parse(company) : null;
    const [userImage, setUserImage] = useState("");

    const deletion = (status: string) => {
        mailStatus(mailInfo.id, { status: status }).then(() => {
            setMailShow(false);
        })
    }

    const onInteriorFileDrop = (event: any) => {
        const imageFile = event.target.files && event.target.files[0];
        setInteriorName(imageFile.name);
        if (imageFile && imageFile.size <= 26 * 1024 * 1024) { // Convert MB to bytes
            setInteriorFile(imageFile);
            if (imageFile && interiorFiles.length === 0) {
                setInteriorFiles([imageFile]);
            }
        } else {
            showNotifications(TOAST_TYPE.error, 'Maximum upload size 26 MB');
        }
    }
    const removeInteriorFile = () => {
        setInteriorFiles([]);
    }
    const interiorAdd = (status: string) => {
        let files: any = {
            "interior": interiorFile,
            "interior_name": interiorName,
            "interior_size": interiorFiles.reduce((totalSize, file) => totalSize + file.size, 0),
            "status": status

        }
        mailStatus(mailInfo.id, files).then(() => {
            setMailShow(false);
        })
    }

    const handleDownloadClick = async (fileName: string) => {
        const imageUrl = `${API}/${fileName}`;
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    return (
        <div>
            <Modal show={mailShow} onHide={handleMailClose} centered size="lg">
                <div className="addMemberForm">
                    <button className='closeModal' onClick={handleMailClose}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>

                    <div className='addMemberHeading justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <img src={folder} alt="member" />
                            <p>Mail {mailInfo.id}</p>
                        </div>
                        <div className='text-end mailStatus'>
                            <span className={mailInfo.status}>{mailInfo.status === "pending" ? "Pending" : mailInfo.status === "shredding" ? "Shredding Request" : mailInfo.status === "shredded" ? "Shredded" : mailInfo.status === "scanning" ? "Scanning Request" : mailInfo.status === "scanned" ? "Scanned" : ""}</span>
                        </div>
                    </div>
                    <div className="mailOptions mb-4">
                        <p>Exterior (Outside)</p>
                    </div>
                    {/* Interior (Contents) */}
                    <div className="uploadFileShow d-flex align-items-center">
                            <div className='d-flex'>
                                <div className="fileFormat">
                                    <img src={fileFormat} alt="file" />
                                </div>
                                <div className="fileName">
                                    <p>{mailInfo?.exterior_name}   {mailInfo.status === "shredded" ? <span className='deleteText'>(DELETE)</span> : "" }</p>
                                    {mailInfo.status === "shredded" ? "" : <span>{convertBytesToSize(mailInfo.exterior_size)} – 100% uploaded</span>}
                                </div>
                            </div>
                            {mailInfo.status === "shredded" ? "" : 
                            <div className="fileDelete">
                                <img onClick={() => handleDownloadClick(mailInfo?.exterior)} src={downloadIcon} alt="" />
                            </div> }
                        </div>
                    {mailInfo?.interior_name ? <>
                        <div className="mailOptions mt-3 mb-4">
                            <p>Interior (Contents)</p>
                        </div>
                        <div className="uploadFileShow d-flex align-items-center">
                            <div className='d-flex'>
                                <div className="fileFormat">
                                    <img src={fileFormat} alt="file" />
                                </div>
                                <div className="fileName">
                                    <p>{mailInfo?.interior_name}   {mailInfo.status === "shredded" ? <span className='deleteText'>(DELETE)</span> : "" }</p>
                                    {mailInfo.status === "shredded" ? "" : <span>{convertBytesToSize(mailInfo.interior_size)} – 100% uploaded</span>}
                                </div>
                            </div>
                            {mailInfo.status === "shredded" ? "" : 
                            <div className="fileDelete">
                                <img onClick={() => handleDownloadClick(mailInfo?.interior)} src={downloadIcon} alt="" />
                            </div> }
                        </div> </> : ""}


                    {mailInfo.status === "scanning" && userInfo.user.role === "admin" ? <>
                        <div className="mailOptions mt-4 mb-4">
                            <p>Interior (Contents)</p>
                        </div>
                        <div ref={wrapperRef} className="drop-file-input mb-4">
                            <div className="drop-file-input__label">
                                <img src={uploadFile} alt="" />
                                <p><span>Click to upload</span> or drag and drop</p>
                                <h6>Maximum upload size <b>26 MB</b></h6>
                            </div>
                            <input type="file" value="" onChange={onInteriorFileDrop} />
                        </div>
                        {interiorFiles && interiorFiles.map((file, index) =>
                            <div className="uploadFileShow">
                                <div className="fileFormat">
                                    <img src={fileFormat} alt="file" />
                                </div>
                                <div className="fileName">
                                    <p>{file.name}</p>
                                    <span>{convertBytesToSize(file.size)} – 100% uploaded</span>
                                </div>
                                <div className="fileDelete" onClick={removeInteriorFile}>
                                    <img src={trash} alt="trash" />
                                </div>
                            </div>
                        )} </> : ""}


                    {mailInfo.status === "shredding" && userInfo.user.role === "admin" ? <div className="memberScanBtn">
                        <button className='scan' onClick={() => setMailShow(false)}>Cancel</button>
                        <button className='delete' onClick={() => deletion("shredded")}>Mark as Shredded</button>
                    </div> : ""}

                    {mailInfo.status === "pending" && userInfo.user.role === "user" ? <div className="memberScanBtn">
                        <button className='scan' onClick={() => deletion("scanning")}>Request Opening / Scanning</button>
                        <button className='delete' onClick={() => deletion("shredding")}>Request Shredding / Deletion</button>
                    </div> : ""}

                    {mailInfo.status === "shredded" ? <div className="memberScanBtn mailCloseBtn d-flex justify-content-end">
                        <button className='close' onClick={() => setMailShow(false)}>Close</button>
                    </div> : ""}

                    {mailInfo.status === "scanning" && userInfo.user.role === "admin" ? <div className="memberScanBtn mailCloseBtn d-flex justify-content-end">
                        <button className='close' onClick={() => interiorAdd("scanned")}>Submit Scan</button>
                    </div> : ""}

                    {mailInfo.status === "scanned" && userInfo.user.role === "user" ? <div className="memberScanBtn mailCloseBtn">
                        <button className='close' onClick={() => setMailShow(false)}>Cancel</button>
                        <button className='delete' onClick={() => deletion("shredding")}>Request Shredding / Deletion</button>
                    </div> : ""}

                </div>

            </Modal>
        </div>
    )
}

export default ViewMail