import deleteCookie from "./deleteCookie";

const logoutUser = async () => {
  localStorage.removeItem("company");
  localStorage.removeItem("singleProfile");
  localStorage.removeItem("token");
  deleteCookie("refreshToken");
  if(window.location.pathname !== "/") {
    window.location.href = "/";
  }
};

export default logoutUser;