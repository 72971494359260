import React, { useEffect, useState } from "react";
import Layout from "../../Component/Layout/Layout";
import http from "../../utils/http";
import endpoints from "../../utils/endpoints";
import "./Door.css"

export default function Door() {
  const [doors, setDoors] = useState([]);
  const [timers, setTimers] = useState<any>({}); // To keep track of timers for each door

  const getDoors = async () => {
    try {
      const res = await http().get(endpoints.doors.doorList);
      setDoors(res.locks);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDoors();
  }, []);

  const getDoor = async (id: string) => {
    try {
      await http().post(`${endpoints.doors.doorList}/${id}/unlock`);
      // Update the door state to reflect that it is unlocked
      setDoors((prevDoors: any) =>
        prevDoors.map((door: any) =>
          door.id === id ? { ...door, open: true } : door
        )
      );
      startTimer(id, 30); // Start a 30 seconds timer after unlocking
    } catch (error) {
      console.error(error);
    }
  };

  const startTimer = (id: string, duration: number) => {
    let timeLeft = duration;
    setTimers((prev: any) => ({ ...prev, [id]: timeLeft }));

    const interval = setInterval(() => {
      timeLeft -= 1;
      setTimers((prev: any) => ({ ...prev, [id]: timeLeft }));

      if (timeLeft <= 0) {
        clearInterval(interval);
        lockDoor(id);
      }
    }, 1000);
  };

  const lockDoor = async (id: string) => {
    try {
      setDoors((prevDoors: any) =>
        prevDoors.map((door: any) =>
          door.id === id ? { ...door, open: false } : door
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <div className='mainContent doorSection'>
        <h3 className='doorHeading'>My Doors</h3>
        {doors?.map((item: any) => (
          <div
            key={item.id}
            style={{
              background: "white",
              padding: "16px",
              borderRadius: "8px",
              marginTop: "16px",
            }}
          >
            <div className='flex justify-content-between align-items-center'>
              <p style={{ fontWeight: "bold" }}>{item?.name}</p>
              <p className={!item.open ? "lock" : "unlock"}>
                {!item.open ? "Locked" : `Unlocked (${timers[item.id] || 0} s)`}
              </p>
            </div>
            <div
              className='loginBtn'
              style={{
                marginTop: "-30px",
              }}
            >
              <button
                onClick={() => getDoor(item.id)}
                disabled={item.open}
                style={{ background: item.open ? "#BAC7CE" : "" }}
              >
                Unlock Door
              </button>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
}
