import { del, get, post, put } from "./base-api";

// mail create
export const exteriorCreate = (body = {}) => {
    return post('exteriorCreate', body, 'multipart/form-data');
};

// mail list
export const mailList = () => {
    return get(`mailList`);
};
// mail status
export const mailStatus = (mailId: string, body = {}) => {
    return put(`mailStatus/${mailId}`, body,'multipart/form-data');
};

export const singleMail = (mailId: string) => {
    return get(`singleMail/${mailId}`);
};

export const mailDelete = (mailId: string) => {
    return del(`mailDelete/${mailId}`);
};

