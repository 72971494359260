import React, { useState } from "react";
import "./Settings.css";
import Layout from "../../Component/Layout/Layout";
import Customization from "./Customization";
import Profile from "./Profile";
import Account from "./Account";
import Finance from "./Finance";
import Users from "./Users";
import Membership from "./Membership";
import Tours from "./Tours";
import SettingsTabs from "./SettingsTabs";
import Integration from "./Integration";

const Settings = () => {
  const [selectedTab, setSelectedTab] = useState("profile");

  return (
    <div id='settings'>
      <Layout>
        <div className='mainContent'>
          <div className="settingPage">
            <SettingsTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />

            {selectedTab === "account" && <Account />}
            {selectedTab === "profile" && <Profile />}
            {selectedTab === "finance" && <Finance />}
            {selectedTab === "agreement" && <Membership />}
            {selectedTab === "tours" && <Tours />}
            {selectedTab === "integration" && <Integration />}

            {/* {selectedTab === "users" && <Users />} */}
            {/* {selectedTab === "custom" && <Customization />} */}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Settings;
