import React, { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { DESKIE_API as API } from '../../config';
import imageInput from "../../Assets/Images/icon/imgButton.svg";
import blankUser from "../../Assets/Images/icon/user-02.svg";
import PhoneInput from 'react-phone-input-2';
import { signUpMember, updateMember } from '../../api/member';
import memberBlank from "../../Assets/Images/icon/memberLargeIcon.png";

interface tabMemberProps {
    tabChoose: (tab: string, select: string) => void;
    memberInfo: any;
}

const MemberPanel = ({ tabChoose, memberInfo }: tabMemberProps) => {

    const [userEdit, setUserEdit] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [file, setFile] = useState("");
    const [imageKey, setImageKey] = useState("");
    const [memberInfos, setMemberInfo] = useState<any>({});
    const urlParams = new URLSearchParams(window.location.search);
    const token: any = urlParams.get("token");
    useEffect(() => {
        signUpMember(token)
          .then((data) => {
            setMemberInfo(data.data.data);
            setFirstName(data.data.data.first_name);
            setLastName(data.data.data.last_name);
            setPhone(data.data.data.phone_number);
            setBusinessName(data.data.data.business_name);
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);
    

    const memberFunction = () => {
        tabChoose("password", "member")
    }

    const editFunction = () => {
        setUserEdit(false)
    }
    function handleChange(e: any) {
        setFile(URL.createObjectURL(e.target.files[0]));
        setImageKey(e.target.files[0]);
    }

     // update member info
     const memberUpdate = () => {
        let member:any = {
            "first_name": firstName,
            "last_name": lastName,
            "phone_number": phone,
            "business_name": businessName
        }
        if (imageKey) {
            member["member_image"] = imageKey;
          }
        updateMember(memberInfos.id, member).then(() => {
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            tabChoose("password", "member")
        })
    }


    return (
        <>
            <div className="profileInfo">
                {userEdit ? <>
                    <h1>Personal Info</h1>
                    <div className="imageUpload mt-4">
                        <div className="upload" style={{marginLeft: "0px"}}>
                            {memberInfos.member_image ? <img className='member' src={`${API}/${memberInfos.member_image}`} alt="" />
                                : <img className='member' src={blankUser} alt="" />}

                            <div className="round">
                                <img src={imageInput} alt="profile" />
                            </div>
                        </div>
                    </div>
                    <div className="memberInput memberSignUp">
                        <label>First Name</label>
                        <input type="text" value={memberInfos.first_name} readOnly name="firstName" placeholder="First Name" className="form-control" />
                    </div>
                    <div className="memberInput memberSignUp">
                        <label>Last Name</label>
                        <input type="text" value={memberInfos.last_name} readOnly name="firstName" placeholder="First Name" className="form-control" />
                    </div>
                    <div className="memberInput memberSignUp">
                        <label>Phone Number</label>
                        <PhoneInput country={'us'} disableCountryCode={false} value={memberInfos.phone_number} inputProps={{ readOnly: true }} />
                    </div>
                    <div className="memberInput memberSignUp">
                        <label>Business Name</label>
                        <input type="text" value={memberInfos.business_name} readOnly name="firstName" placeholder="First Name" className="form-control" />
                    </div>
                    <div className="memberInput memberSignUp">
                        <label>Email Address</label>
                        <input type="text" value={memberInfos.email} readOnly name="firstName" placeholder="First Name" className="form-control" />
                    </div>
                    <div className="tabPanelBtn">
                        <button className='backBtn' onClick={editFunction}>Edit</button>
                        <button className='continue' onClick={memberFunction}>Continue <FontAwesomeIcon icon={faArrowRight} /></button>
                    </div>
                </> : <>
                    <h1>Edit Personal Info</h1>
                    <div className="imageUpload mt-4">
                        <div className="upload" style={{marginLeft: "0px"}}>
                            {/* {memberInfos.member_image ? <img className='member' src={`${API}/${memberInfos.member_image}`} alt="" />
                                : <img className='member' src={blankUser} alt="" />}

                            <div className="round">
                                <img src={imageInput} alt="profile" />
                            </div> */}

                            {file && file.length > 0 ? <img src={file} width="100px" height="100px" alt="shop" />
                                            : <>
                                            {memberInfos.member_image ? <div style={{ width: "80px", height: "80px", overflow: "hidden", borderRadius: "50%" }}>
                                            <img src={`${API}/${memberInfos.member_image}`} alt="avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                        </div>
                                            :  <img src={memberBlank} width="100px" height="100px" alt="shop" />}
                                            </>
                                        }
                                        <div className="round">
                                            <input type="file" onChange={handleChange} required />
                                            <img src={imageInput} alt="profile" />
                                        </div>
                        </div>
                    </div>
                    <div className="memberInput memberSignUp">
                        <label>First Name</label>
                        <input type="text" value={firstName} onChange={(e)=>setFirstName(e.target.value)} name="firstName" placeholder="First Name" className="form-control" />
                    </div>
                    <div className="memberInput memberSignUp">
                        <label>Last Name</label>
                        <input type="text" value={lastName} onChange={(e)=>setLastName(e.target.value)} name="firstName" placeholder="First Name" className="form-control" />
                    </div>
                    <div className="memberInput memberSignUp">
                        <label>Phone Number</label>
                        <PhoneInput country={'us'} disableCountryCode={false} value={phone} onChange={(value) => setPhone(value)} />
                    </div>
                    <div className="memberInput memberSignUp">
                        <label>Business Name</label>
                        <input type="text" value={businessName} onChange={(e)=>setBusinessName(e.target.value)} name="firstName" placeholder="First Name" className="form-control" />
                    </div>
                    <div className="memberInput memberSignUp">
                        <label>Email Address</label>
                        <input type="text" value={memberInfos.email} readOnly name="firstName" placeholder="First Name" className="form-control" />
                    </div>
                    <div className="tabPanelBtn">
                        <button className='backBtn' onClick={()=>setUserEdit(true)}><FontAwesomeIcon icon={faArrowLeft} /> Cancel</button>
                        <button className='continue' onClick={memberUpdate}>Save & Continue <FontAwesomeIcon icon={faArrowRight} /></button>
                    </div>
                </>}




                {/* <div className="tabPanelBtn">
                    <button className='backBtn'><FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                    <button className='continue' onClick={memberFunction}>Continue <FontAwesomeIcon icon={faArrowRight} /></button>
                </div> */}

            </div>
        </>
    )
}

export default MemberPanel