import React, { useEffect, useRef, useState } from 'react';
import sanitation from "../../Assets/Images/icon/clean_hands.svg";
import maintenance from "../../Assets/Images/icon/tool-02.svg";
import membership from "../../Assets/Images/icon/account_circle.svg";
import other from "../../Assets/Images/icon/dots-vertical_large.svg";
import temperature from "../../Assets/Images/icon/thermometer-03.svg";
import noise from "../../Assets/Images/icon/volume_up.svg";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ticketUpdate, ticketView } from '../../api/tickets';
import { useParams,useNavigate } from 'react-router-dom';
import Layout from '../../Component/Layout/Layout';
import { DESKIE_API as API } from '../../config';
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';
import { ToastContainer } from 'react-toastify';


const issueTypeImages: { [key: string]: string } = {
    "maintenance": maintenance,
    "membership": membership,
    "sanitation": sanitation,
    "other": other,
    "temperature": temperature,
    "noise": noise
};
const ViewReport = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [issueType, setIssueType] = useState(0);
    const [ticketInfo, setTicketInfo] = useState<any>({})
    const issueImage = issueTypeImages[ticketInfo?.report_type] || other;
    useEffect(() => {
        if (id) {
            ticketView(id).then((data) => {
                setTicketInfo(data.data)
            }).catch((err) => { console.log(err) });
        }
    }, [issueType]);

    const ticketPage=() => {
        navigate("/tickets");
    }
const ticketStatus=() => {
    if (id) {
        ticketUpdate(id).then((data) => {
            setIssueType(issueType+1)
        showNotifications(TOAST_TYPE.success, "Status Change Successfully");
        }).catch((err) => { console.log(err) });
    }
}
    return (
        <>
            <Layout>
                <ToastContainer/>
                <div className='mainContent'>
                    <section className="reportIssue">
                        <div className='reportIssueHeading'>
                            <h1><FontAwesomeIcon onClick={ticketPage} icon={faArrowLeft} />Detail</h1>
                            <button onClick={ticketStatus} className={ticketInfo?.status ? "btn open" : "btn closed"}>{ticketInfo?.status ? "Open" : "Closed"}</button>
                        </div>
                        <div className="reportDetails">
                            <div className="reportTag">
                                <h1>Your report choice:</h1>
                                <div className='reportTagBox'>
                                    <img src={issueImage} alt={ticketInfo?.report_type} />
                                    <p>{ticketInfo?.report_type}</p>
                                </div>
                            </div>
                            <div className="reportInfo w-100">
                                <p className='mb-2'>Room Location</p>
                                <div className='roomName'>
                                    <p>{ticketInfo?.space_name}</p>
                                </div>
                                <p className='mb-2'>Member Name</p>
                                <div className='roomName'>
                                    <p>{ticketInfo?.member_name}</p>
                                </div>
                                {ticketInfo?.evidence_image ? 
                                <><p className='mb-2'>Photo Evidence (optional)</p>
                                <div className='evidenceImage'>
                                    <img src={`${API}/${ticketInfo?.evidence_image}`} alt="evidence" />
                                </div>
                                </>
                                 : ""}
                                
                                <p className='mb-2 mt-3'>Description</p>
                                <div className='issueDescription'>
                                    <textarea value={ticketInfo?.description}></textarea>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        </>
    )
}

export default ViewReport