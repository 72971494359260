import { get, post, put } from './base-api';

// chat
export const getChatHistory = (body = {}) => {
    return post(`/chatHistory`, body);
}

// chatter list
export const getChatters = (email: string) => {
    return get(`/chatters/${email}`);
};
// unreadChatters
export const unreadChatters = (email: string) => {
    return get(`/unreadChatters/${email}`);
};


// chatter list
export const chatSeen = (sender: string, recipient: string, role: string) => {
    return put(`/chatSeen/${sender}`, { recipient, role });
};
