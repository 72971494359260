import { get, post, put } from "./base-api";


// admin login
export const loginAdmin = (body = {}) => {
    return post(`/auth/login`, body);
};

// admin login
export const memberPassword = (body = {}) => {
    return put(`/memberPassword`, body);
};

export const getUsers = () => {
    return get("/users")
}
export const adminList = () => {
    return get("/adminList")
}
// admin check

export const adminCheck = (id: string) => {
    return get(`/adminCheck/${id}`)
}

// export const updateAdmin = async (email:string,body = {}) => {
//     return put(`/adminUpdate/${email}`,body)
// }

export const updateAdmin = (email:string,body = {}) => {
    return put(`/adminUpdate/${email}`, body, 'multipart/form-data');
};