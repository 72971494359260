import React from "react";
import { Card } from "react-bootstrap";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";

interface StripeFilledCardProps {
  memberData: {
    card_last4: string;
    name_on_card: string;
    card_brand: string;
    card_exp_month: string;
    card_exp_year: string;
  },
  setUpdateCard: React.Dispatch<React.SetStateAction<boolean>>
}

const FilledCard = ({memberData, setUpdateCard}: StripeFilledCardProps) => {
  const pad = (d:number) => {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  return (
    <Card className="filledCard">
      <div className="filledCard__head">
        <h4>Payment Method</h4>
        <p className="action" onClick={() => setUpdateCard(true)}>Change Payment Method</p>
      </div>

      <div className="filledCard__info">
        <p className="filledCard__infoLabel">NAME:</p>
        <p className="filledCard__infoValue">{memberData.name_on_card}</p>
      </div>

      <div className="filledCard__info">
        <p className="filledCard__infoLabel">CARD:</p>
        <p className="filledCard__infoValue">
          <span className="cardName">{capitalizeFirstLetter(memberData.card_brand)}{' '}</span>
          <span>ending with</span>
          <span>{' '}{memberData.card_last4}</span>
        </p>
      </div>

      <div className="filledCard__info">
        <p className="filledCard__infoLabel">EXPIRY DATE:</p>
        <p className="filledCard__infoValue">{pad(+memberData.card_exp_month)}/{memberData.card_exp_year}</p>
      </div>
    </Card>
  )
};

export default FilledCard;