import React, { useEffect } from "react";
import Routing from "./Routing";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-datepicker/dist/react-datepicker.css";
// import ResponsiveModal from './Component/ResponsiveModal/ResponsiveModal';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function App() {
  useEffect(() => {
    if (window.location.href === "https://deskie.com") {
      window.location.href = "https://www.deskie.com";
    }
  }, []);

  return (
    <>
      <Routing />
      {/* <ResponsiveModal></ResponsiveModal> */}
      <ToastContainer></ToastContainer>
    </>
  );
}

export default App;
