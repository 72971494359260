import React from 'react'
import { Modal } from 'react-bootstrap';
import { DESKIE_API as API } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../Pagination/Pagination';
import { formatResourceDate } from '../../CommonFunction/Function';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { Link, useNavigate } from "react-router-dom";
import spaceAvatar from "../../Assets/Images/icon/spaceAvatar.png";

interface ResourcesProps {
    handleClose: () => void;
    show: boolean;
    setShow: (type: boolean) => void;
    upcomingBookings: any;
    page: any;
    setPage: any;
    limit: any;
    setLimit: any;
    prevButton: any;
    nextButton: any;
    pageValue: any;
    totalValue: any;
    prevPage: any;
    nextPage: any;
    handlePaymentShow: any;
};

const ResourceMobile = ({ handlePaymentShow, show, setShow, handleClose, upcomingBookings, page, setPage, limit, setLimit, prevButton, nextButton, pageValue, totalValue, prevPage, nextPage }: ResourcesProps) => {
    const navigate = useNavigate();
    const viewResource = (resourceId: string) => {
        return navigate(`${resourceId}`);
    };
    return (
        <Modal show={show} onHide={handleClose} centered size="lg" className='mobileFilesModal'>
            <h1 className='filesHeading'><span onClick={handleClose} className="icon"><FontAwesomeIcon icon={faArrowLeft} /></span> Resources</h1>
            <div className="filesContent">
                <div className='mobileFilesHeading mt-2'>
                    <h6>Available Resources</h6>
                    {/* <button className='viewAll'>Select All</button> */}
                </div>
                {upcomingBookings && upcomingBookings.map((data: any, i: number) => <div
                        key={`resource` + i}
                        className='bookingPerson'
                        style={{
                          flexDirection: "column",
                          alignItems: "start",
                          gap: 16,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className='tableImage justify-content-center'
                            style={{ cursor: "pointer" }}
                            onClick={() => viewResource(data.id)}
                          >
                            {data.image ? (
                              <img
                                src={`${API}/${data.image}`}
                                alt='avatar'
                                style={{
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <img
                                src={spaceAvatar}
                                alt='avatar'
                                style={{ borderRadius: "50%" }}
                              />
                            )}{" "}
                          </div>
                          <div className='tableLink'>
                            <Link to={`${data.id}`}>{data.name}</Link>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className='resourceType'>
                            {data.type === "meeting" ? (
                              <span className='meeting'>Meeting Space</span>
                            ) : (
                              ""
                            )}
                            {data.type === "equipment" ? (
                              <span className='equipment'>Equipment</span>
                            ) : (
                              ""
                            )}
                            {data.type === "workspace" ? (
                              <span className='workspace'>Workspace</span>
                            ) : (
                              ""
                            )}
                            {data.type === "other" ? (
                              <span className='other'>Other</span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className='editBtn'>
                            <button
                              style={{
                                width: "100%",
                                height: "100%",
                                paddingTop: "4px",
                                paddingBottom: "4px",
                              }}
                              onClick={() => handlePaymentShow(data)}
                            >
                              Reserve
                            </button>
                          </div>
                        </div>
                      </div>
                )}
            </div>
            <Pagination page={page} paginationTitle="files" setPage={setPage} limit={limit} setLimit={setLimit} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} allRequestList={upcomingBookings} />
        </Modal>
    )
}

export default ResourceMobile