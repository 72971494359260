import React, { useEffect, useRef, useState } from 'react';
import sanitation from "../../Assets/Images/icon/clean_hands.svg";
import maintenance from "../../Assets/Images/icon/tool-02.svg";
import membership from "../../Assets/Images/icon/account_circle.svg";
import other from "../../Assets/Images/icon/dots-vertical_large.svg";
import temperature from "../../Assets/Images/icon/thermometer-03.svg";
import noise from "../../Assets/Images/icon/volume_up.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { getSpacesList } from '../../api/spaces';
import uploadFile from "../../Assets/Images/icon/uploadIcon.svg";
import fileFormat from "../../Assets/Images/icon/file-05.svg";
import trash from "../../Assets/Images/icon/trash_delete.svg";
import send from "../../Assets/Images/icon/sent_plane.svg";
import { v4 as uuidv4 } from 'uuid';
import { ticketCreate } from '../../api/tickets';

interface IssueProps {
    issueType: string;
    setIssueType: any;
}
const issueTypeImages: { [key: string]: string } = {
    "maintenance": maintenance,
    "membership": membership,
    "sanitation": sanitation,
    "other": other,
    "temperature": temperature,
    "noise": noise
};

const ReportDetails = ({ issueType, setIssueType }: IssueProps) => {
    const issueImage = issueTypeImages[issueType] || other;
    const [spacesList, setSpacesList] = useState([]);
    const [searchMembers, setSearchMembers] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [selectName, setSelectName] = useState("");
    const [description, setDescription] = useState("");
    const [spacesId, setSpacesId] = useState("");
    const [file, setFile] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const handleMemberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchMembers(e.target.value);
    };
    const wrapperRef = useRef<HTMLInputElement>(null);
    const onFileDrop = (event: any) => {
        const imageFile = event.target.files && event.target.files[0];
        setFile(imageFile)
        if (imageFile && uploadedFiles.length === 0) {
            setUploadedFiles([imageFile]);
        }
        // const files = Array.from(event.target.files);
        // setUploadedFiles((prev: any) => [...prev, ...files]);
    }
    // remove file
    const removeFile = () => {
        setUploadedFiles([]);
    }
    useEffect(() => {
        getSpacesList(20, 1, searchMembers).then((data) => {
            setSpacesList(data.spaces);
        }).catch((err) => { console.log(err) });
    }, [searchMembers]);
    const filteredSpaces = spacesList?.filter((member: any) =>
        member.name.toLowerCase().includes(searchMembers.toLowerCase())
    );

    const selectMember = (member: any) => {
        setSelectName(`${member.name}`);
        setSpacesId(member.id);
    };

    const ticketAdd = () => {
        const formData = new FormData();  // Create FormData object

        formData.append("id", uuidv4());
        formData.append("report_type", issueType);
        formData.append("asset_name", spacesId);
        formData.append("description", description);
        formData.append("evidence_image", file);
        // uploadedFiles.forEach((file) => {
        //     formData.append("evidence_image", file); 
        // });
        ticketCreate(formData).then((data) => {
            setIssueType("")
            setFile("")
            setUploadedFiles([]);
        }).catch((err) => { console.log(err) });
    }
    return (
        <>
            <section className="reportIssue">
                <div className='reportIssueHeading'>
                    <h1><FontAwesomeIcon onClick={()=>setIssueType("")} icon={faArrowLeft} />Detail</h1>
                </div>
                <div className="reportDetails">
                    <div className="reportTag">
                        <h1>Your report choice:</h1>
                        <div className='reportTagBox'>
                            <img src={issueImage} alt={issueType} />
                            <p>{issueType}</p>
                        </div>
                    </div>
                    <div className="reportInfo w-100">
                        <p className='mb-2'>Room Location</p>
                        <div className='dropdown reportInfoSide mb-2'>
                            <div onClick={(e) => { setIsActive(!isActive); }} className="dropdown-btn" >
                                <div className='tableImage'>
                                    {selectName.length ? <>
                                        <p>{selectName.length > 28 ? selectName.substring(0, 28) + '...' : selectName}</p>
                                    </> : <>
                                        <div><p>Choose Room location</p></div>
                                    </>}
                                </div>
                                <span><FontAwesomeIcon icon={faChevronDown} /></span>
                            </div>
                            <div className="dropdown-content" style={{ display: isActive ? "block" : "none" }}>
                                <div className='assignInput px-2'>
                                    <FontAwesomeIcon icon={faSearch} />
                                    <input type="text" placeholder='Search member' onChange={handleMemberChange} className='form-control' />
                                </div>
                                <div className='member-container'>
                                    {filteredSpaces.map((data: any, index) =>
                                        <div key={`assignSpace` + index} onClick={(e) => { setIsActive(!isActive); selectMember(data); }} className="item tableImage" >
                                            <p>{data.name.length > 17 ? data.name.slice(0, 17) + "..." : data.name}</p>
                                        </div>)}
                                </div>
                            </div>
                        </div>
                        <p className='mb-2'>Photo Evidence (optional)</p>
                        <div className='issueFiles'>
                            <div ref={wrapperRef} className="drop-file-input">
                                <div className="drop-file-input__label">
                                    <img src={uploadFile} alt="" />
                                    <p><span>Click to upload</span> or drag and drop</p>
                                    <h6>Maximum upload size <b>26 MB</b></h6>
                                </div>
                                <input type="file" value="" onChange={onFileDrop} />
                            </div>
                            <div className="issueImages">
                                {uploadedFiles && uploadedFiles.map((file: any, index: number) =>
                                    <div className="issueFileShow">
                                        <div className="fileFormat">
                                            <img src={URL.createObjectURL(file)} alt="file" />
                                        </div>
                                        <div className="fileDelete" onClick={removeFile}>
                                            <img src={trash} alt="trash" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <p className='mb-2'>Description</p>
                        <div className='issueDescription'>
                            <textarea onChange={(e) => setDescription(e.target.value)}></textarea>
                        </div>
                        <div className="submitIssue d-flex justify-content-end">
                            <button className='btn' onClick={ticketAdd}>Send Report <img src={send} alt="trash" /></button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReportDetails