import React, { useState } from 'react';
import Layout from '../../Component/Layout/Layout';
import "./ReportIssue.css";
import hand from "../../Assets/Images/icon/clean_hands.svg";
import tool from "../../Assets/Images/icon/tool-02.svg";
import account from "../../Assets/Images/icon/account_circle.svg";
import dots from "../../Assets/Images/icon/dots-vertical_large.svg";
import thermometer from "../../Assets/Images/icon/thermometer-03.svg";
import noise from "../../Assets/Images/icon/volume_up.svg";
import ReportDetails from './ReportDetails';

const ReportIssue = () => {
    const [issueReport, setIssueReport] = useState(true);
    const [issueType, setIssueType] = useState("");
    return (
        <>
            <Layout>
                <div className='mainContent'>
                    {issueType.length ? <ReportDetails issueType={issueType} setIssueType={setIssueType} />
                    : <section className="reportIssue">
                        <div className='reportIssueHeading d-flex flex-column align-items-start'>
                            <h1>What seems to be the problem?</h1>
                            <p>Select a ticket category</p>
                        </div>
                        <div className="reportBoxList">
                            <div className="reportBox" onClick={()=>setIssueType("noise")}>
                                <img src={noise} alt="hand" />
                                <p>Noise</p>
                            </div>
                            <div className="reportBox" onClick={()=>setIssueType("temperature")}>
                                <img src={thermometer} alt="hand" />
                                <p>Temperature</p>
                            </div>
                            <div className="reportBox" onClick={()=>setIssueType("sanitation")}>
                                <img src={hand} alt="hand" />
                                <p>Sanitation</p>
                            </div>
                            <div className="reportBox" onClick={()=>setIssueType("maintenance")}>
                                <img src={tool} alt="hand" />
                                <p>Maintenance</p>
                            </div>
                            <div className="reportBox" onClick={()=>setIssueType("membership")}>
                                <img src={account} alt="hand" />
                                <p>Membership</p>
                            </div>
                            <div className="reportBox" onClick={()=>setIssueType("other")}>
                                <img src={dots} alt="hand" />
                                <p>Other</p>
                            </div>
                        </div>
                    </section> }
                    {/*  */}
                </div>
            </Layout>
        </>
    )
}

export default ReportIssue