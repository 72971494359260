import React, { useEffect, useState } from 'react'
import Layout from '../../../Component/Layout/Layout';
import "./MyHome.css";
import assetsIcon from "../../../Assets/Images/icon/assignmentIcon.svg";
import avatar from "../../../Assets/Images/icon/blank-profile.jpg";
import onlineShow from "../../../Assets/Images/icon/online.svg";
import ticketIcon from "../../../Assets/Images/icon/ticketIcon.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpLong, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button, Table } from 'react-bootstrap';
import moment from 'moment';
import copyBtn from "../../../Assets/Images/icon/content_copy.svg";
import memberBlank from "../../../Assets/Images/icon/memberAvatar.svg";
import spacesBlank from "../../../Assets/Images/icon/spaceAvatar.png";
import { getInvoicesList } from '../../../api/invoice';
import more from "../../../Assets/Images/icon/dots-vertical.svg";
import { DESKIE_API as API } from '../../../config';
import memberAvatar from "../../../Assets/Images/icon/memberAvatar.svg";
import spaceAvatar from "../../../Assets/Images/icon/spaceAvatar.png";
import invoiceIcon from "../../../Assets/Images/icon/receipt.png";
import wallet from "../../../Assets/Images/icon/wallet.svg";
import wifiIcon from "../../../Assets/Images/icon/wifi.svg";
import wifiQr from "../../../Assets/Images/icon/wifiScan.svg";
import spacesIcon from "../../../Assets/Images/icon/spacesImage.png";
import { memberInvoice, memberSpaces, singleJwtMember } from '../../../api/member';
import { Link } from 'react-router-dom';
import { isAuthenticate } from '../../../api/auth';
import { unreadChatters } from '../../../api/message';
import { CHATTER } from '../../../CommonFunction/types';
import { enhanceGroupUserName, messageFormatDate } from '../../../CommonFunction/Function';

const MyHome = () => {
  const [spacesList, setSpacesList] = useState<any>([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  let auth = isAuthenticate();
  const [users, setUsers] = useState<CHATTER[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const company = localStorage.getItem("company");
        if (company) {
          const storage = JSON.parse(company);
          
          // Fetch chatters with unread messages
          const chatters = await unreadChatters(storage.user.email);
          setUsers(chatters);
        }
  
        // Fetch user data
        const userData = await singleJwtMember();
        setFirstName(userData.data.data.first_name);
        setLastName(userData.data.data.last_name);
  
        // Fetch spaces data
        const spacesData = await memberSpaces(auth?.user?.id);
        setSpacesList(spacesData.spaces || []);
        
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, []);
  

  return (
    <Layout>
      <div className='mainContent' id='my-home'>
        <div className="memberHome">
          {/* <h5>Welcome Back, <span>{firstName} {lastName}</span></h5> */}
          <div className="memberDashboard">
            <div className="memberLeft">
              <div className="assetsTicket">
                <Link to="/my-resources" className="ticketItem">
                  <p><img src={assetsIcon} alt="assets" /><span>Book a Resource</span></p>
                  <button><FontAwesomeIcon icon={faPlus} /></button>
                </Link>
                <div className="ticketItem">
                  <p><img src={ticketIcon} alt="ticket" /><span>Submit a Ticket</span></p>
                  <button><FontAwesomeIcon icon={faPlus} /></button>
                </div>
              </div>
              <div className="latestInvoice">
                <div className="latestInvoiceHeading">
                  <h5 style={{ borderBottom: '0' }}><img src={invoiceIcon} alt="assets" />Active Assignments</h5>
                </div>
                <div className='memberAssign activeAssign'>
                  {spacesList && spacesList.length ?
                    spacesList && spacesList.map((spaces: any) =>
                      <div className="invoiceHeading">
                        <div className="invoiceName">
                          <p>{spaces.spaces_image ? <img src={`${API}/${spaces.spaces_image}`} alt="member" /> : <img src={spacesIcon} alt="spaces" />} <span>{spaces.spaces_name}</span> </p>
                          <div className='deskType'>
                            {spaces.spaces_tag === "private" ? <span className='private'>Private Office</span> : ""}
                            {spaces.spaces_tag === "dedicated" ? <span className='dedicated'>Dedicated Desk</span> : ""}
                            {spaces.spaces_tag === "flex" ? <span className='flex'>Flex</span> : ""}
                          </div>
                        </div>

                        <div className="invoicePrice billingAction">
                          <p>${spaces.amount} <span>/mo</span> </p>
                          <button className='btn download'><img src={more} alt="download" /></button>
                        </div>
                      </div>) : <p className='mb-0'>No active assignments.</p>
                  }
                </div>

              </div>
              {/* <div className="latestInvoice invoiceHistory">
                <div className='mb-3 d-flex justify-content-between pendingInvoice'>
                  <h6 className='mb-0'><img src={wallet} alt="wallet" /> Pending Invoices</h6>
                  <p className='mb-0 balance'>Balance: <span>-$50.45</span></p>
                </div>
                <div className="invoiceMemberList mobileInvoices">
                  <div className="invoiceBox">
                    <div className="invoiceHeading">
                      <div className="invoiceName">
                        <h6>#INV288</h6>
                      </div>
                      <div className="invoicePrice billingAction">
                        <p>$10 <span>/mo</span> </p>
                        <button className='btn download'><img src={more} alt="download" /></button>
                      </div>
                    </div>
                    <div className="invoiceDetails">
                      <div className="assign assignMobile">
                        <h6>Assignment</h6>
                        <p><img src={spacesBlank} alt="member" /> <span>invoice.spaces_name</span> </p>
                      </div>
                      <div className='pendingInvoicesDue'>
                        <div className="assign">
                          <h6>Due Date</h6>
                          <p>April 1, 2024</p>
                        </div>
                        <div className="assign">
                          <h6>Status</h6>
                          <p className='status'><span className='paid'>Paid</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="memberRight">
              {/* <div className="upcomingBook">
                <h5><img src={assetsIcon} alt="assets" />Upcoming Bookings</h5>
                <div className="bookingList p-3">
                  <img src={spacesIcon} alt="member" />
                  <div className="spacesInfo">
                    <h6>sadasd</h6>
                    <p>asa</p>
                  </div>
                </div>
              </div> */}
              <div className="messageChatBox">
                <div className='messageHeading'>
                  <h5><img src={wifiIcon} alt="assets" />Unread Messages</h5>
                  <Link to="/my-messenger">View All</Link>
                </div>
                <div className="chatList ">
                {users.length > 0 && users?.map((e, i) => 
                  <>
                  <div className="chatBox">
                    <div className="avatar">
                    {e.avatar ? <img className="avatar-icon1" alt="" src={`${API}/${e.avatar}`} />
                                            : <img className="avatar-icon1" alt="" src={avatar} />}
                     {e.online_status ? (<div className="avatar-online-indicator">
                                            <img alt="" src={onlineShow} />
                                        </div>) : ""}
                    </div>
                    <div className="text">
                      <div className="bogdan-krivenchenko">{e.group_id
    ? enhanceGroupUserName(e.name ?? "").slice(0, 15) + (e.name && e.name.length > 15 ? "..." : "")
    : e.name?.slice(0, 15) + (e.name && e.name.length > 15 ? "..." : "")}</div>
                      <div className="hi-how-are">{e.message?.length > 20 ? `${e.message.substring(0, 20)}...` : e.message}</div>
                    </div>
                  </div>
                  <div className="parent">
                    <div className="div16">{messageFormatDate(e.created_at)}</div><div className="ellipse"></div>
                  </div>
                  </>
                  )}
                   {users.length === 0 ? <div className="bogdan-krivenchenko">No Data</div> : ""}
                </div>
              </div>
              {/* <div className="wifiQr mt-3">
                <h5><img src={wifiIcon} alt="assets" />Wifi Access</h5>
                <div className='qr'>
                  <img src={wifiQr} alt="wifi" />
                  <p className='text-center'>Scan QR code to connect, or enter manually:</p>
                  <div className="wifiInfo">
                    <span>SSID</span>
                    <input className='form-control' placeholder='BuzzCoworking' />
                    <button><img src={copyBtn} alt="copyBtn" /></button>
                  </div>
                  <div className="wifiInfo mt-3">
                    <span>Password</span>
                    <input className='form-control' placeholder='welovefreewifi' />
                    <button><img src={copyBtn} alt="copyBtn" /></button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MyHome