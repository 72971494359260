import React,{useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Elements, useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import { loadStripe } from '@stripe/stripe-js';
import PaymentCard from './PaymentCard';
import { getStripe} from '../../api/settings';
import { tab } from '@testing-library/user-event/dist/tab';

interface tabMemberProps {
    tabChoose: (tab: string, select: string) => void;
}

const Billing = ({ tabChoose }: tabMemberProps) => {
    const [stripePromise, setStripePromise] = useState<any>("");
    useEffect(() => {
        getStripe().then((data) => {
            setStripePromise(data.data.publish_key);
        }).catch((err) => {console.log(err)});
    }, []);
    return (
        <>
            <div className="billingInfo">
                <h1>Billing Info</h1>
                <div className="memberInput memberSignUp">
                    <label>Name on Card</label>
                    <input type="text" name="firstName" placeholder="Name on Card" className="form-control" />
                </div>
                <div className="stripePayment">
                    {stripePromise ?  <Elements stripe={loadStripe(stripePromise)}>
                        <PaymentCard  tabChoose={tabChoose} />
                    </Elements>: ""}
                   

                </div>
                <div className="tabPanelBtn">
                    <button className='back' onClick={() => tabChoose("password", "billing")}><FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                    <button className='skip' onClick={() => tabChoose("agreement", "billing")}>Skip <FontAwesomeIcon icon={faArrowRight} /></button>
                    {/* <button className='continue' onClick={() => tabChoose("agreement", "billing")}>Continue <FontAwesomeIcon icon={faArrowRight} /></button> */}
                </div>
            </div>
        </>
    )
}

export default Billing