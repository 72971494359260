import { Dispatch, SetStateAction } from "react";

interface SettingsTabsProps {
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
}

const SettingsTabs: React.FC<SettingsTabsProps> = ({
  selectedTab,
  setSelectedTab,
}) => {
  return (
    <div className='companyOptions'>
      <button
        className={selectedTab === "account" ? "activeBtn" : ""}
        onClick={() => setSelectedTab("account")}
      >
        Personal Profile
      </button>
      <button
        className={selectedTab === "profile" ? "activeBtn" : ""}
        onClick={() => setSelectedTab("profile")}
      >
        Company Profile
      </button>
      <button
        className={selectedTab === "finance" ? "activeBtn" : ""}
        onClick={() => setSelectedTab("finance")}
      >
        Finances
      </button>
      <button
        className={selectedTab === "agreement" ? "activeBtn" : ""}
        onClick={() => setSelectedTab("agreement")}
      >
        Onboarding
      </button>
      <button
        className={selectedTab === "tours" ? "activeBtn" : ""}
        onClick={() => setSelectedTab("tours")}
      >
        Tours
      </button>
      <button
        className={selectedTab === "integration" ? "activeBtn" : ""}
        onClick={() => setSelectedTab("integration")}
      >
        Integration
      </button>
    </div>
  );
};

export default SettingsTabs;
