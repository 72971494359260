import React, { useEffect, useState } from "react";
import Layout from "../../Component/Layout/Layout";
import { Dropdown, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import trashIcon from "../../Assets/Images/icon/trash-icon.svg";
import { bookedSpaces } from "../../api/spaces";
import { DESKIE_API as API } from "../../config";
import { Link, useNavigate } from "react-router-dom";
import spaceAvatar from "../../Assets/Images/icon/spaceAvatar.png";
import "./Assignments.css";
import memberAvatar from "../../Assets/Images/icon/memberAvatar.svg";
import Pagination from "../../Component/Pagination/Pagination";
import { deleteAssignment, getAssignment } from "../../api/assignments";
import editPen from "../../Assets/Images/icon/edit-01.svg";
import AssignmentPrice from "../../Component/AssignmentPrice/AssignmentPrice";
import ConfirmationModal from "../../Component/ConfirmationModal/ConfirmationModal";
import { TOAST_TYPE, showNotifications } from "../../CommonFunction/toaster";

type Assignment = {
  amount: number;
  id: string;
  member_id: string;
  member_image: string | null;
  member_name: string;
  space_id: string;
  spaces_image: string | null;
  spaces_name: string;
  tag: string;
};

const Assignments = () => {
  const navigate = useNavigate();
  const [assignList, setAssignList] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [totalValue, setTotalValue] = useState<any>();
  const [limitValue, setLimitValue] = useState<any>();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState<number>(10);
  const pageCount = Math.ceil(totalValue / limitValue);
  const [prevButton, setPrevButton] = useState<boolean>(false);
  const [nextButton, setNextButton] = useState<boolean>(false);
  const [pageValue, setPageValue] = useState<number>();
  const [show, setShow] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState<Assignment>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleClose = () => setShow(false);

  const assignView = (assignment: Assignment) => {
    setShow(true);
    setSelectedAssignment(assignment);
  };

  const getAssignmentList = () => {
    getAssignment(limit, page)
      .then((data) => {
        setAssignList(data.assignments);
        setTotalValue(data && data.total);
        setLimitValue(data && data.limit);
        setPageValue(data && data.page);
        setShowConfirmationModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAssignmentList();
  }, [page, limit, show]);

  useEffect(() => {
    if (pageCount > 1) {
      setPrevButton(true);
    }
    if (page === 1) {
      setPrevButton(false);
    }
    // next button
    if (pageCount > 1) {
      setNextButton(true);
    }
    if (pageCount === page) {
      setNextButton(false);
    }
  }, [pageCount, page]);
  const nextPage = () => {
    setPage(page + 1);
    setNextButton(true);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  // view
  const spacesView = (spacesId: string) => {
    return navigate(`${spacesId}`);
  };
  const handleSort = (columnName: string) => {
    if (sortBy === columnName) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnName);
      setSortOrder("asc");
    }
  };

  const deleteAssignmentHandler = async () => {
    await deleteAssignment(selectedAssignment?.id as string);
    showNotifications(TOAST_TYPE.success, "Assignment deleted");
    getAssignmentList();
  };

  return (
    <Layout>
      <div className='mainContent' id='tour-log'>
        <div className='memberBox'>
          <div className='topLine'>
            <div className='tableHeading'>
              <h6>All Assignments</h6>
            </div>
            <div className='memberSearch'>
              <div className='searchInput'>
                <input
                  type='text'
                  placeholder='Search tours'
                  className='form-control'
                />
                <FontAwesomeIcon icon={faSearch} />
              </div>
            </div>
          </div>

          <div className='spaceList'>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>
                    <label className='tableCheckBox'>
                      <div className='contactCheck'>
                        <input type='checkbox' name='agreement' />
                        <span className='checkmark'></span>
                      </div>
                    </label>
                  </th>
                  <th style={{ justifyContent: "left", textAlign: "left" }}>
                    Name
                  </th>
                  <th>Member</th>
                  <th>Type</th>
                  <th>Rate</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {assignList &&
                  assignList.map((data: any, i: number) => (
                    <tr key={`assign` + i}>
                      <td>
                        <label className='tableCheckBox'>
                          <div className='contactCheck'>
                            <input type='checkbox' name='agreement' />
                            <span className='checkmark'></span>
                          </div>
                        </label>
                      </td>
                      <td
                        className='tableLink'
                        style={{ width: "150px", alignItems: "left" }}
                      >
                        <div
                          className='tableImage justify-content-left'
                          style={{ cursor: "pointer" }}
                        >
                          {data.spaces_image ? (
                            <img
                              src={`${API}/${data.spaces_image}`}
                              alt='avatar'
                              style={{
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                          ) : (
                            <img
                              src={spaceAvatar}
                              alt='avatar'
                              style={{ borderRadius: "50%" }}
                            />
                          )}{" "}
                          <Link to={`/assets/${data.space_id}`}>
                            {data.spaces_name}
                          </Link>
                        </div>
                      </td>
                      <td className='tableLink'>
                        {data.member_image ? (
                          <img
                            className='avatar-icon36'
                            alt=''
                            src={`${API}/${data.member_image}`}
                          />
                        ) : (
                          <img
                            className='avatar-icon36 default'
                            alt=''
                            src={memberAvatar}
                          />
                        )}{" "}
                        <Link to={`/member/${data.member_id}`}>
                          {data.member_name}
                        </Link>
                      </td>
                      <td className='deskType'>
                        {data.tag === "private" ? (
                          <span className='private'>Private Office</span>
                        ) : (
                          ""
                        )}
                        {data.tag === "dedicated" ? (
                          <span className='dedicated'>Dedicated Desk</span>
                        ) : (
                          ""
                        )}
                        {data.tag === "flex" ? (
                          <span className='flex'>Flex</span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td className='rate'>
                        ${parseFloat(data.amount).toFixed(2)} <small>/mo</small>
                      </td>
                      <td>
                        <td className='tableAction'>
                          <button
                            className='btn edit'
                            onClick={() => assignView(data)}
                          >
                            <img src={editPen} alt='edit' />
                          </button>
                          <button
                            className='btn edit'
                            onClick={() => {
                              setSelectedAssignment(data);
                              setShowConfirmationModal(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} color='#f33' />
                          </button>
                        </td>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Pagination
              page={page}
              paginationTitle='items'
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              prevButton={prevButton}
              nextButton={nextButton}
              pageValue={pageValue}
              totalValue={totalValue}
              prevPage={prevPage}
              nextPage={nextPage}
              allRequestList={assignList}
            />
          </div>
        </div>
      </div>
      <AssignmentPrice
        assignInfo={selectedAssignment || {}}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
      />
      <ConfirmationModal
        ConfirmationShow={showConfirmationModal}
        handleConfirmationClose={() => setShowConfirmationModal(false)}
        afterConfirmationApi={deleteAssignmentHandler}
      />
    </Layout>
  );
};

export default Assignments;
