import React, { useState, useEffect } from "react";
import { Col, Container, Dropdown, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faChevronDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import resourceIcon from "../../Assets/Images/icon/booking.svg";
import stepCheck from "../../Assets/Images/icon/stepCheck.svg";
import stepDot from "../../Assets/Images/icon/stepDot.svg";
import stepBlank from "../../Assets/Images/icon/stepBlank.svg";
import ResourceDetails from "../ResourceBooking/ResourceDetails";
import ResourceSchedule from "../ResourceBooking/ResourceSchedule";
import ResourcePayment from "../ResourceBooking/ResourcePayment";
import ResourceDone from "../ResourceBooking/ResourceDone";
import { v4 as uuidv4 } from "uuid";
import { isAuthenticate } from "../../api/auth";
import {
  publicResourceBooking,
  resourceBooking,
  resourceInvoice,
} from "../../api/resource";
import {
  getPublicInvoiceId,
  getLastInvoice,
  invoiceAmountUpdate,
  invoiceUpdate,
  publicPaymentInvoice,
} from "../../api/invoice";
import { memberAddSpaces, publicAddMemberToSpace } from "../../api/spaces";
import { showNotifications, TOAST_TYPE } from "../../CommonFunction/toaster";
import { findTimeGap } from "../../CommonFunction/Function";
import { paymentProcess, publicCreatePaymentIntent } from "../../api/payment";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import creditCard from "../../Assets/Images/icon/credit_card.svg";
import { getStripe, getStripeCredentials } from "../../api/settings";
import { createMember } from "../../api/member";

interface AddResourcePaymentProps {
  handlePaymentClose: () => void;
  paymentShow: boolean;
  setPaymentShow: (type: boolean) => void;
  resourceDetails?: any;
  isPublic?: boolean;
}

const options = {
  style: {
    base: {
      fontSize: "15px",
      fontWeight: 400,
      color: "rgba(54, 54, 55, 1)",
      letterSpacing: "0.025em",
      fontFamily: "inter",
      "::placeholder": {
        color: "rgba(116, 118, 121, 0.8)",
      },
    },
    invalid: {
      color: "rgba(54, 54, 55, 1)",
    },
  },
};

// payment

interface tabPaymentProps {
  tabChoose: (tab: string, select: string) => void;
  resourceBooked: any;
  resourceDetail: any;
  selectedDate: string;
  startTime: string;
  endTime: string;
  setPaymentShow: any;
  authValue: any;
  isPublic?: boolean;
}

const CardPaymentOption = ({
  authValue,
  setPaymentShow,
  selectedDate,
  startTime,
  endTime,
  resourceDetail,
  tabChoose,
  resourceBooked,
  isPublic = false,
}: tabPaymentProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardName, setCardName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const createPaymentIntentMethod = isPublic
    ? publicCreatePaymentIntent
    : paymentProcess;

  const resourceBookingMethod = isPublic
    ? publicResourceBooking
    : resourceBooking;

  const paymentInvoiceMethod = isPublic ? publicPaymentInvoice : invoiceUpdate;

  let auth = isAuthenticate();

  const amountValue =
    findTimeGap(startTime, endTime) *
    parseInt(
      isPublic ? resourceDetail.public_rate : resourceDetail.member_rate
    );

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements) {
      console.error("Stripe.js has not loaded yet.");
      return;
    }

    setIsLoading(true);

    createPaymentIntentMethod({ amount: amountValue * 100 })
      .then(async (data) => {
        if (elements) {
          const cardElement = elements.getElement(CardNumberElement);

          if (cardElement) {
            const paymentIntentResult = await stripe.confirmCardPayment(
              data.clientSecret,
              {
                payment_method: {
                  card: cardElement,
                },
              }
            );

            if (paymentIntentResult.error) {
              showNotifications(TOAST_TYPE.error, "Wrong Information");
            } else if (
              paymentIntentResult.paymentIntent &&
              paymentIntentResult.paymentIntent.status === "succeeded"
            ) {
              // In case of guest user create member account first then proceed further.
              if (isPublic) {
                const newMemberData = {
                  id: uuidv4(),
                  firstName: firstName,
                  lastName: lastName,
                  phoneNumber: phoneNumber,
                  email: email,
                  business_name: "",
                  business_phone: phoneNumber,
                  business_email: email,
                  role: "user",
                  created_at: new Date(),
                };
                try {
                  const res = await createMember(newMemberData);
                  auth = res?.data;
                } catch (error: any) {
                  console.log(error);
                  setIsLoading(false);
                  return;
                }
              }

              let resourceInfo = {
                id: uuidv4(),
                firstName: firstName,
                email: auth.user?.email,
                resource_id: resourceDetail.id,
                amount:
                  findTimeGap(startTime, endTime) *
                  parseInt(
                    isPublic
                      ? resourceDetail.public_rate
                      : resourceDetail.member_rate
                  ),
                book_date: selectedDate,
                start_time: startTime,
                end_time: endTime,
                created_by: auth.user.id,
                invoice_add: authValue,
                booking_type: authValue ? "end_of_month" : "immidiate",
              };
              const invId = uuidv4();

              const bookingData = {
                member_id: auth.user.id,
                asset_id: resourceDetail.id,
                asset_name: "resource",
                amount:
                  findTimeGap(startTime, endTime) *
                  parseInt(
                    isPublic
                      ? resourceDetail.public_rate
                      : resourceDetail.member_rate
                  ),
              };

              resourceBookingMethod(resourceInfo)
                .then((data) => {
                  showNotifications(TOAST_TYPE.success, data.message);

                  let paymentConfirm = {
                    id: uuidv4(),
                    invoiceId: data.invoice_id,
                    invoiceNumber: data.invoice_id,
                    userId: auth.user.id,
                    amount: `${findTimeGap(startTime, endTime) *
                      parseInt(
                        isPublic
                          ? resourceDetail.public_rate
                          : resourceDetail.member_rate
                      )
                      }`,
                    paymentDate: new Date(),
                    method: "Visa",
                    paymentNote: "",
                    status: "paid",
                    invoiceAmount:
                      findTimeGap(startTime, endTime) *
                      parseInt(
                        isPublic
                          ? resourceDetail.public_rate
                          : resourceDetail.member_rate
                      ),
                    name: firstName + lastName,
                    email: email,
                    phoneNumber: phoneNumber,
                    city: city,
                    card_name: cardName,
                    street: street,
                    state: state,
                    zip_code: zip,
                    stripe_id: paymentIntentResult.paymentIntent.client_secret,
                  };
                  paymentInvoiceMethod(paymentConfirm)
                    .then((data) => {
                      console.log("pay", data);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                })
                .catch((err) => {
                  showNotifications(TOAST_TYPE.error, err.message);
                  console.log(err);
                  setIsLoading(false);
                })
                .finally(() => {
                  setPaymentShow(false);
                });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const payInvoice = () => {
    tabChoose("done", "billing");
  };
  const backSchedule = () => {
    tabChoose("schedule", "billing");
  };
  const [payView, setPayView] = useState(false);
  const payResource = () => {
    setPayView(true);
  };

  return (
    <>
      <div className=''>
        {payView ? (
          ""
        ) : (
          <>
            <form onSubmit={handleSubmit} className='DemoWrapper'>
              {isPublic && (
                <>
                    <h6 className='mb-2 mt-4'><img src={creditCard} alt="payemnt" /> Payment</h6>
                  <h6 className='mb-2 mt-4'>Personal Details</h6>
                  <div className='d-flex justify-content-between flex-wrap'>
                    <div className='memberInput'>
                      <label>First Name</label>
                      <input
                        type='text'
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder='First Name'
                        className='form-control'
                        required
                      />
                    </div>
                    <div className='memberInput'>
                      <label>Last Name</label>
                      <input
                        type='text'
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder='Last Name'
                        className='form-control'
                      />
                    </div>
                  </div>

                  <div className='d-flex justify-content-between flex-wrap'>
                    <div className='memberInput'>
                      <label>Email Address</label>
                      <input
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Email Address'
                        className='form-control'
                        required
                      />
                    </div>
                    <div className='memberInput'>
                      <label>Phone Number</label>
                      <input
                        type='number'
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder='Phone Number'
                        className='form-control'
                      />
                    </div>
                  </div>
                </>
              )}
              <h6 className='mb-2 mt-4'>Payment Details</h6>
              <div className='cardPayment'>
                <div className='resourceInput'>
                  <label>Name on Card</label>
                  <input
                    className='form-control'
                    value={cardName}
                    onChange={(e) => setCardName(e.target.value)}
                    placeholder='Name on card'
                  />
                </div>
                <div className='cardNumber resourceCard mt-0'>
                  <div className='cardInput'>
                    <label>Card Number</label>
                    <CardNumberElement options={options} />
                  </div>
                  <div className='cardInput expire'>
                    <label>Expiration Date</label>
                    <CardExpiryElement options={options} />
                  </div>
                  <div className='cardInput cvv'>
                    <label>CVV</label>
                    <CardCvcElement options={options} />
                  </div>
                </div>
              </div>
              <h6 className='mb-2 mt-4'>Billing Details</h6>
              <div className='d-flex justify-content-between flex-wrap'>
                <div className='memberInput'>
                  <label>Street Address</label>
                  <input
                    type='text'
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    placeholder='Street Address'
                    className='form-control'
                  />
                </div>
                <div className='memberInput'>
                  <label>City</label>
                  <input
                    type='text'
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder='City'
                    className='form-control'
                  />
                </div>
              </div>
              <div className='d-flex justify-content-between flex-wrap'>
                <div className='memberInput'>
                  <label>State / Province</label>
                  <input
                    type='text'
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    placeholder='State / Province'
                    className='form-control'
                  />
                </div>
                <div className='memberInput'>
                  <label>Zip Code</label>
                  <input
                    type='text'
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    placeholder='Zip Code'
                    className='form-control'
                  />
                </div>
              </div>
              <div className='resourcePrice'>
                <div>
                  <p>
                    Duration:{" "}
                    {startTime
                      ? findTimeGap(startTime, endTime)
                      : ""}{" "}
                    {isPublic
                      ? `${resourceDetail.public_time}s`
                      : `${resourceDetail.member_time}s`}
                  </p>
                  <p>
                    $
                    {isPublic
                      ? resourceDetail.public_rate
                      : resourceDetail.member_rate}
                  </p>
                </div>
                <div>
                  <p>Total</p>
                  <p>
                    $
                    {(startTime
                      ? findTimeGap(startTime, endTime)
                      : 0) *
                      parseInt(
                        isPublic
                          ? resourceDetail.public_rate
                          : resourceDetail.member_rate
                      )}
                  </p>
                </div>
              </div>
              <div className='resourcesBtn'>
                <button className='cancel' onClick={backSchedule}>
                  <FontAwesomeIcon className='mr-2' icon={faArrowLeft} /> Back
                </button>
                <button type='submit' className='continue' disabled={isLoading}>
                  {isLoading ? (
                    <span className='loader'></span>
                  ) : (
                    <>
                      Continue <FontAwesomeIcon icon={faArrowRight} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </>
        )}
      </div>{" "}
    </>
  );
};

const MobileBilling = ({ handlePaymentClose, paymentShow, setPaymentShow, resourceDetails, isPublic = false, }: AddResourcePaymentProps) => {
  let auth = isAuthenticate();
  const [selectedTabs, setSelectedTabs] = useState<string[]>([]);
  const [startTime, setStartTime] = useState("Choose");
  const [endTime, setEndTime] = useState("Choose");
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [authValue, setAuthValue] = useState(false);
  const [detailsTab, setDetailsTab] = useState(true);
  const [scheduleTab, setScheduleTab] = useState(false);
  const [billingTab, setBillingTab] = useState(false);
  const [finishTab, setFinishTab] = useState(false);
  const [cardName, setCardName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [instantPay, setInstantPay] = useState(true);
  const [stripePromise, setStripePromise] = useState<any>("");

  useEffect(() => {
    if (isPublic) {
      getPublicInvoiceId()
        .then((data) => {
          setInvoiceId(`00${data.data}`);
        })
        .catch((err) => {
          console.log(err);
        });

      getStripeCredentials()
        .then((data) => {
          setStripePromise(data.data.publish_key);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getLastInvoice()
        .then((data) => {
          setInvoiceId(`00${data.data}`);
        })
        .catch((err) => {
          console.log(err);
        });

      getStripe()
        .then((data) => {
          setStripePromise(data.data.publish_key);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const tabChoose = (tab: string, selectTab: string) => {
    setSelectedTabs((prevTabs) => {
      const tabExists = prevTabs.includes(selectTab);
      if (tabExists) {
        return prevTabs;
      } else {
        return [...prevTabs, selectTab];
      }
    });

    switch (tab) {
      case "details":
        setDetailsTab(true);
        setScheduleTab(false);
        setBillingTab(false);
        setFinishTab(false);
        break;
      case "schedule":
        setDetailsTab(false);
        setScheduleTab(true);
        setBillingTab(false);
        setFinishTab(false);
        break;
      case "billing":
        setDetailsTab(false);
        setScheduleTab(false);
        setBillingTab(true);
        setFinishTab(false);
        break;
      case "done":
        setDetailsTab(false);
        setScheduleTab(false);
        setBillingTab(false);
        setFinishTab(true);
        break;
      default:
        setDetailsTab(true);
        setScheduleTab(false);
        setBillingTab(false);
        setFinishTab(false);
    }
  };
  // check tab duplicate
  function checkValueExist(value: string, valuesArray: string[]) {
    return valuesArray.includes(value);
  }


  const resourceBooked = () => {
    let resourceInfo = {
      id: uuidv4(),
      resource_id: resourceDetails.id,
      amount:
        findTimeGap(startTime, endTime) *
        parseInt(
          isPublic ? resourceDetails.public_rate : resourceDetails.member_rate
        ),
      book_date: selectedDate,
      start_time: startTime,
      end_time: endTime,
      created_by: auth.user.id,
      invoice_add: authValue,
      booking_type: authValue ? "end_of_month" : "immidiate",
    };
    const invId = uuidv4();
    let invoiceResource = {
      id: invId,
      spaces_id: resourceDetails.id,
      member_id: auth.user.id,
      amount:
        findTimeGap(startTime, endTime) *
        parseInt(
          isPublic ? resourceDetails.public_rate : resourceDetails.member_rate
        ),
      renewal_date: selectedDate,
      renewal_frequency: "resource",
      user_email: auth.user.email,
      invoice_type: "resource",
    };
    let invoiceMonthly = {
      id: uuidv4(),
      spaces_id: resourceDetails.id,
      member_id: auth.user.id,
      amount:
        findTimeGap(startTime, endTime) *
        parseInt(
          isPublic ? resourceDetails.public_rate : resourceDetails.member_rate
        ),
      renewal_date: selectedDate,
      renewal_frequency: "monthly",
      user_email: auth.user.email,
      invoice_type: "resource",
    };
    let paymentInfo = {
      id: uuidv4(),
      invoiceId: uuidv4(),
      userId: auth.user.id,
      amount:
        findTimeGap(startTime, endTime) *
        parseInt(
          isPublic ? resourceDetails.public_rate : resourceDetails.member_rate
        ),
      paymentDate: new Date(),
      method: "card",
      paymentNote: "",
      status: "paid",
      invoiceAmount:
        findTimeGap(startTime, endTime) *
        parseInt(
          isPublic ? resourceDetails.public_rate : resourceDetails.member_rate
        ),
    };
    let totalAmount = {
      amount:
        findTimeGap(startTime, endTime) *
        parseInt(
          isPublic ? resourceDetails.public_rate : resourceDetails.member_rate
        ),
    };

    resourceBooking(resourceInfo)
      .then((data) => {
        showNotifications(TOAST_TYPE.success, data.message);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPaymentShow(false);
      });
    // memberAddSpaces(invoiceMonthly)
    //   .then(() => {
    // invoiceAmountUpdate(auth.user.id, totalAmount)
    //   .then(() => {})
    //   .catch((err) => {

    //   })
    //   .finally(() => {
    //     setPaymentShow(false);
    //   });
    // })
    // .catch((err) => {
    //   // showNotifications(TOAST_TYPE.error, err.message);
    //   console.log(err);
    // });
  };

  const authClick = () => {
    setAuthValue(!authValue);
  };
  const paymentFunction = () => {
    if (authValue) {
      tabChoose("done", "billing");
    } else {
      setInstantPay(false);
    }
  };
  const payInvoice = () => {
    tabChoose("done", "billing");
  };
  const backSchedule = () => {
    tabChoose("schedule", "billing");
  };
  return (
    <>
      <div className='resourceBook tabResourcePanel'>
        <div className='tabHeading'>
          <ul className='tablist'>
            <li className='mt-0'>
              <div className='arrowLine'>
                <div
                  className={`checkCircle ${checkValueExist("details", selectedTabs) &&
                    "checked"
                    }`}
                >
                  {checkValueExist("details", selectedTabs) ? (
                    <img
                      className='checked'
                      src={stepCheck}
                      alt='stepCheck'
                    />
                  ) : (
                    <>
                      {detailsTab ? (
                        <img src={stepDot} alt='stepCheck' />
                      ) : (
                        <img src={stepBlank} alt='stepCheck' />
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className='arrowHeading'>
                <h6 className={detailsTab ? "selected" : ""}>
                  Details
                </h6>
                <p className={detailsTab ? "selected" : ""}>
                  Resource info
                </p>
              </div>
            </li>
            <li className=''>
              <div className='arrowLine'>
                <div
                  className={`checkCircle ${checkValueExist("schedule", selectedTabs) &&
                    "checked"
                    }`}
                >
                  {checkValueExist("schedule", selectedTabs) ? (
                    <img src={stepCheck} alt='stepCheck' />
                  ) : (
                    <>
                      {scheduleTab ? (
                        <img src={stepDot} alt='stepCheck' />
                      ) : (
                        <img src={stepBlank} alt='stepCheck' />
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className='arrowHeading'>
                <h6 className={scheduleTab ? "selected" : ""}>
                  Schedule
                </h6>
                <p className={scheduleTab ? "selected" : ""}>
                  Date, time, etc
                </p>
              </div>
            </li>
            {resourceDetails.public_rate !== "0" && (
              <li className=''>
                <div className='arrowLine'>
                  <div
                    className={`checkCircle ${checkValueExist("billing", selectedTabs) &&
                      "checked"
                      }`}
                  >
                    {checkValueExist("billing", selectedTabs) ? (
                      <img src={stepCheck} alt='stepCheck' />
                    ) : (
                      <>
                        {billingTab ? (
                          <img src={stepDot} alt='stepCheck' />
                        ) : (
                          <img src={stepBlank} alt='stepCheck' />
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className='arrowHeading'>
                  <h6 className={billingTab ? "selected" : ""}>
                    Payment
                  </h6>
                  <p className={billingTab ? "selected" : ""}>
                    Billing
                  </p>
                </div>
              </li>
            )}
            <li className=''>
              <div className='arrowLine'>
                <div
                  className={`checkCircle done ${checkValueExist("done", selectedTabs) && "checked"
                    }`}
                >
                  {checkValueExist("done", selectedTabs) ? (
                    <img src={stepCheck} alt='stepCheck' />
                  ) : (
                    <>
                      {" "}
                      {finishTab ? (
                        <img src={stepCheck} alt='stepCheck' />
                      ) : (
                        <img src={stepBlank} alt='stepCheck' />
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className='arrowHeading'>
                <h6 className={finishTab ? "selected" : ""}>Done!</h6>
              </div>
            </li>
          </ul>
        </div>
        <div className='paymentInfo'>
          {detailsTab ? (
            <ResourceDetails
              resourceDetail={resourceDetails}
              tabChoose={tabChoose}
              isPublic={isPublic}
            />
          ) : (
            ""
          )}
          {scheduleTab ? (
            <ResourceSchedule
              resourceDetail={resourceDetails}
              startTime={startTime}
              setStartTime={setStartTime}
              endTime={endTime}
              setEndTime={setEndTime}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              tabChoose={tabChoose}
              isPublic={isPublic}
            />
          ) : (
            ""
          )}
          {billingTab ? (
            <>
              <div className='resourcePay'>
                <div className='resourcePayHeading'>
                  <h6 style={{ marginBottom: "32px" }}>
                    Payment
                  </h6>
                </div>

                {instantPay ? (
                  <div className='resourcePayment'>
                    <div className='cardPayment'>
                      {stripePromise ? (
                        <Elements stripe={loadStripe(stripePromise)}>
                          <CardPaymentOption
                            authValue={authValue}
                            setPaymentShow={setPaymentShow}
                            selectedDate={selectedDate}
                            startTime={startTime}
                            endTime={endTime}
                            resourceDetail={resourceDetails}
                            resourceBooked={resourceBooked}
                            tabChoose={tabChoose}
                            isPublic={isPublic}
                          />
                        </Elements>
                      ) : (
                        ""
                      )}
                    </div>

                  </div>
                ) : ""}
              </div>
            </>
          ) : (
            ""
          )}
          {finishTab ? (
            <ResourceDone
              selectedDate={selectedDate}
              startTime={startTime}
              endTime={endTime}
              resourceDetail={resourceDetails}
              resourceBooked={resourceBooked}
              tabChoose={tabChoose}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  )
}

export default MobileBilling