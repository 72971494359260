import React, { useEffect, useState } from "react";
import Layout from "../../../Component/Layout/Layout";
import MyProfile from "./MyProfile";
import MyAccount from "./MyAccount";
import "./MySetting.css";
import { getStripe, stripeSetupIntent } from "../../../api/settings";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { memberMe } from "../../../api/member";
import FilledCard from "./FilledCard";

interface StripeKeys {
  publish_key: string | null;
  secret_key: string | null;
}

const MySetting = () => {
  const [selectedTab, setSelectedTab] = useState("profile");
  const [loading, setLoading] = useState(true);
  const [updateCard, setUpdateCard] = useState(false);
  const [stripeKeys, setStripeKeys] = useState<StripeKeys>({
    publish_key: "",
    secret_key: "",
  });
  const [memberData, setMemberData] = useState({
    card_last4: "",
    name_on_card: "",
    card_brand: "",
    card_exp_month: "",
    card_exp_year: ""
  });

  const getMember = async () => {
    try {
      const member = await memberMe();
      setMemberData(member);
      
      const { data } = await getStripe();
      setStripeKeys(data);
    } catch {}
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getMember();
  }, []);

  const renderMemberCard = () => {
    if(loading) {
      return <div>Loading...</div>
    };

    if(!stripeKeys.publish_key) {
      return <div>Stripe is not configured properly.</div>
    };

    if(memberData.card_last4 && !updateCard) {
      return <FilledCard 
        memberData={memberData}
        setUpdateCard={setUpdateCard}
      />
    }

    return (
      <Elements stripe={loadStripe(stripeKeys.publish_key)}>
        <MyAccount />
      </Elements>
    )
  };

  return (
    <>
      <Layout>
        <div className='mainContent'>
          <div className='settingPage'>
            <div className='companyOptions'>
              <button
                className={selectedTab === "profile" ? "activeBtn" : ""}
                onClick={() => setSelectedTab("profile")}
              >
                Personal Profile
              </button>
              <button
                className={selectedTab === "account" ? "activeBtn" : ""}
                onClick={() => setSelectedTab("account")}
              >
                Billing Settings
              </button>
            </div>

            {selectedTab === "profile" && <MyProfile />}

            {selectedTab === "account" && renderMemberCard()}
          </div>
        </div>

        {/*
          {companyProfile ? <Profile settingTab={settingTab} /> : ""}
          {companyAccount ? <Account settingTab={settingTab} /> : ""}
          {companyFinance ? <Finance settingTab={settingTab} /> : ""}
          {companyUsers ? <Users settingTab={settingTab} /> : ""}
          {companyCustomization ? <Customization settingTab={settingTab} /> : ""}
        */}
      </Layout>
    </>
  );
};

export default MySetting;
