import { get, post, put } from "./base-api";

// profile add
export const profileAdd = (body = {}) => {
  return post(`/profileCreate`, body, "multipart/form-data");
};

// single profile

export const singleProfile = async () => {
  const cachedProfile = localStorage.getItem("singleProfile");
  if (cachedProfile) {
    return JSON.parse(cachedProfile);
  }
  const response = await get(`/profileSingle`);
  localStorage.setItem("singleProfile", JSON.stringify(response));
  return response;
  // return get(`/profileSingle`);
};

// update profile
export const updateProfile = (id: string, body = {}) => {
  return put(`/updateProfile/${id}`, body, "multipart/form-data");
};

// customization add
export const customizationAdd = (body = {}) => {
  return post(`/customCreate`, body, "multipart/form-data");
};
// stripe Create
export const stripeCreate = (body = {}) => {
  return post(`/stripeCreate`, body);
};
// stripe info
export const getStripe = async () => {
  return get(`/getStripe`);
};

export const getStripeCredentials = async () => {
  return get(`/public/stripe-credentials`);
};

// stripe update
export const stripeUpdate = async (id: string, body = {}) => {
  return put(`/stripeUpdate/${id}`, body);
};

export const getKeys = async () => {
  return get('/config');
}

export const keyUpdate = (body = {}) => {
  return post(`/config`, body);
};
// stripe setup intent
export const stripeSetupIntent = (body = {}) => {
  return post(`/stripe/create-setup-intent`, body);
};

// stripe add payment method (save card on stripe)
export const addPaymentMethod = (body = {}) => {
  return post(`/member/add-payment-method`, body);
};
