const endpoints = {
  settings: {
    singleProfile: "profileSingle",
  },
  member: {
    jwtMemberSingle: "jwtMemberSingle",
    memberCreate: "memberCreate",
    addMemberToSpace: "addMemberToSpace",
    memberList: "memberList",
    allMemberList: "allMemberList",
    memberSingle: "memberSingle",
  },
  doors: {
    doorList: "/kisi/locks",
  },
};

export default endpoints;