type QueryUpdates = {
  [key: string]: string | number | undefined;
};

const updateQueryString = (queryString: string, updates: QueryUpdates) => {
  const urlParams = new URLSearchParams(queryString);

  Object.keys(updates).forEach((key) => {
    const value = updates[key];
    if (value !== undefined) {
      urlParams.set(key, String(value));
    }
  });

  return urlParams.toString();
};

export default updateQueryString;