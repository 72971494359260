import React, { useState } from 'react'
import Layout from '../../Component/Layout/Layout'
import Editor from './Editor';
import Global from './Global';
import "./Website.css";

const Website = () => {
    const [selectedTab, setSelectedTab] = useState("global");
    return (
        <>
            <Layout>
                <div className='mainContent'>
                    <div className="settingPage">
                        <div className='companyOptions'>
                            <button className={selectedTab === "global" ? "activeBtn" : ""} onClick={() => setSelectedTab("global")}>Global Settings</button>
                            <button className={selectedTab === "editor" ? "activeBtn" : ""} onClick={() => setSelectedTab("editor")}>Page Editor</button>
                        </div>
                        {selectedTab === "global" && <Global />}
                        {selectedTab === "editor" && <Editor />}
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Website